import { TrashIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ShareButton } from '@/components/ShareButton/ShareButton';
import { useGetDepositJsonLD } from '@/hooks/microdata/useGetDepositJsonLD';
import { useExternalRedirect } from '@/hooks/useExternalRedirect';
import { getBankLogoURL } from '@/services/supabase';
import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';
import { COUNTRIES_PATHS } from '@/utils/countries';
import { getSlug } from '@/utils/getSlug';
import { isMobileApp } from '@/utils/isMobileApp';
import { textMutation } from '@/utils/textMutation';

import { BadgeDeposit } from '../BadgeDeposit';
import { Icon } from '../Sprite';
import { ButtonsCard, CardDetails, DeletePopup } from './parts';
import { DepositTerms } from './parts/DepositTerms/DepositTerms';

type BankCardProps = {
  bank: IBankDepositCard;
  details?: boolean;
  className?: string;
  favoritePage?: boolean;
  isFavourite?: boolean;
  isBlogBankCard?: boolean;
  handleFavourite?: (bank: IBankDepositCard) => void;
  canShare?: boolean;
};

export const BankCard: FC<BankCardProps> = ({
  bank,
  details = true,
  className,
  favoritePage = false,
  isFavourite,
  isBlogBankCard,
  handleFavourite,
  canShare = true,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [src, setSrc] = useState(bank.bankIndex);
  const router = useRouter();

  const { redirectToExternalLink } = useExternalRedirect();

  // TODO: need to improve this
  // const handleDeleteFavourite = useFavoriteStore(
  //   (state) => state.deleteFavorite,
  // );

  // const handleMobileNavigation: MouseEventHandler<HTMLDivElement> = (e) => {
  //   if (!isMobile) return;
  //
  //   e.preventDefault();
  //
  //   // Navigate to deposit detail page
  //   router.push(`/deposits/${bank.productIndex}`);
  // };

  const isMobile = useMediaQuery({
    query: '(max-width: 960px)',
  });

  const toggleDetails = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.toggleDetails,
      category: AnalyticsCategory.deposit,
      label: 'toggleDetails',
      value: details ? 1 : 0,
    });

    setDetailsOpen(!isDetailsOpen);
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (e.target) {
      setSrc('mockup');
    }
  };

  const handleFavoriteClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (isFavourite) {
      setIsOpenPopup(true);
    } else {
      handleFavourite?.(bank);
    }
  };
  useEffect(() => {
    if (!isFavourite) {
      setIsOpenPopup(false);
    }
  }, [isFavourite]);

  const jsonLd = useGetDepositJsonLD(bank);

  return (
    <div
      className={cn(
        'shadow-far relative flex max-w-full flex-col rounded-xl bg-white',
        className,
      )}
    >
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
      <div className="body3 top-0 -mt-2 ml-[14px] flex flex-wrap gap-1 font-semibold md:absolute md:left-[22px] md:ml-0 md:mt-0 md:-translate-y-1/2 md:gap-2">
        {['savings account', 'demand account'].includes(
          bank.productType.toLowerCase(),
        ) && (
          <>
            {bank.cashback && bank.cashback !== 'no' && (
              <BadgeDeposit variant="cashback" />
            )}
            {(!bank.annualFee || bank.annualFee === 'free') && (
              <BadgeDeposit variant="free-maintenance" />
            )}
          </>
        )}
        {bank.isPartnerBank && <BadgeDeposit variant="available" />}
        {bank.depositType === 'subordinated deposit' && (
          <BadgeDeposit variant="subordinated" />
        )}
        {bank.depositType.toLowerCase() === 'ordinary deposit' && (
          <BadgeDeposit variant="protected" />
        )}
      </div>

      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (bank.productType.toLowerCase() === 'fixed term deposit') {
            router.push({
              pathname: `/deposits/${bank.productIndex}`,
            });
            return;
          }
          if (
            ['savings account', 'demand account'].includes(
              bank.productType.toLowerCase(),
            ) &&
            isMobile
          ) {
            redirectToExternalLink({ bank, onSuccess: () => {} });
          }
        }}
        className={cn(
          'flex-1 cursor-pointer px-4 pb-4 pt-[20px] md:px-6 lg:pt-[24px] xl:pb-3 xl:pt-7',
        )}
      >
        <div
          className={cn(
            'flex justify-between',
            isBlogBankCard && 'xl:justify-normal',
          )}
        >
          <div
            className={cn(
              'md:justify-between xl:w-full xl:min-w-[320px] xl:max-w-[370px]',
              isBlogBankCard && 'xl:max-w-[280px] xl:min-w-[280px]',
            )}
          >
            <div className="flex gap-[6px] md:gap-3">
              {bank.bankSlug ? (
                <Link
                  href={{
                    pathname: isMobileApp()
                      ? `/deposits/${bank.productIndex}`
                      : `/banks/${getSlug(COUNTRIES_PATHS[bank.bankSlugCountry][locale || 'en'])}/${bank.bankSlug}`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="shrink-0"
                >
                  <Image
                    src={getBankLogoURL(src)}
                    alt="bankImage"
                    width={64}
                    height={64}
                    loading="lazy"
                    onError={handleImageError}
                    className={cn(
                      'h-6 w-6 flex-shrink-0 rounded-full border bg-blue-900/10 min-[375px]:h-[38px] min-[375px]:w-[38px] md:h-[40px] md:w-[40px] lg:h-[56px] lg:w-[56px] xl:h-[64px] xl:w-[64px]',
                      isBlogBankCard && 'xl:h-14 xl:w-14',
                    )}
                  />
                </Link>
              ) : (
                <Image
                  src={getBankLogoURL(src)}
                  // alt={bank.productIndex.toString()}
                  alt="bankImage"
                  width={64}
                  height={64}
                  loading="lazy"
                  onError={handleImageError}
                  className={cn(
                    'h-6 w-6 rounded-full border bg-blue-900/10 min-[375px]:h-[38px] min-[375px]:w-[38px] md:h-[40px] md:w-[40px] lg:h-[56px] lg:w-[56px] xl:h-[64px] xl:w-[64px]',
                    isBlogBankCard && 'xl:h-14 xl:w-14',
                  )}
                />
              )}
              <div className="flex max-w-sm flex-col leading-tight">
                <div className="flex flex-col text-start text-black">
                  <Link
                    href={{
                      pathname: `/deposits/${bank.productIndex}`,
                    }}
                    className="md:hidden"
                  >
                    <p
                      className={cn(
                        'line-clamp-2 min-w-[114px] max-w-[114px] text-[15px] font-bold capitalize leading-[20px] tracking-[0.2px] text-blue-900 md:line-clamp-none md:min-w-[200px] md:max-w-[200px] xl:min-w-[260px] xl:max-w-[260px] xl:text-[18px] xl:font-semibold xl:leading-[24px] xl:tracking-[0.1px]',
                        isBlogBankCard &&
                          'md:min-w-[180px] md:max-w-[180px] lg:min-w-[200px] lg:max-w-[200px] xl:min-w-[220px] xl:max-w-[220px]',
                      )}
                    >
                      {bank.bankName}
                    </p>
                  </Link>
                  <p
                    className={cn(
                      'line-clamp-2 hidden md:block min-w-[114px] max-w-[114px] text-[15px] font-bold capitalize leading-[20px] tracking-[0.2px] text-blue-900 md:line-clamp-none md:min-w-[200px] md:max-w-[200px] xl:min-w-[260px] xl:max-w-[260px] xl:text-[18px] xl:font-semibold xl:leading-[24px] xl:tracking-[0.1px]',
                      isBlogBankCard &&
                        'md:min-w-[180px] md:max-w-[180px] lg:min-w-[200px] lg:max-w-[200px] xl:min-w-[220px] xl:max-w-[220px]',
                    )}
                  >
                    {bank.bankName}
                  </p>
                  <p className="body3 text-grey-600">
                    {t(`database.countries.${bank.country}`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <DepositTerms
              isBlogBankCard={isBlogBankCard}
              bank={bank}
              className={cn(
                isBlogBankCard &&
                  'xl:min-w-[83px] xl:max-w-[83px] xl:gap-4 xl:ml-4',
              )}
            />
            {canShare && (
              <ShareButton
                value={`/deposits/${bank.productIndex}`}
                className="-mt-0.5 ml-3 size-5 shrink-0 md:ml-0 lg:hidden"
              />
            )}
          </div>

          <div
            className={cn(
              'hidden gap-2 lg:flex lg:gap-4',
              isBlogBankCard && 'xl:gap-3 xl:pl-[212px]',
            )}
          >
            <ButtonsCard
              bank={bank}
              isPartnerBank={bank.isPartnerBank}
              isBlogBankCard={isBlogBankCard}
            />
            <div className="hidden lg:flex lg:items-start">
              {favoritePage ? (
                <button
                  type="button"
                  className="border-none"
                  onClick={() => setIsOpenPopup(false)}
                >
                  <TrashIcon className="h-5 w-6 cursor-pointer text-grayColor" />
                </button>
              ) : (
                <button
                  type="button"
                  className="cursor-pointer border-none hover:text-pink-500"
                  onClick={handleFavoriteClick}
                >
                  <Icon
                    name={
                      isFavourite ? 'button-fav-is-active' : 'button-to-fav'
                    }
                    className="size-8"
                  />
                </button>
              )}
              {canShare && (
                <ShareButton
                  value={`/deposits/${bank.productIndex}`}
                  className="-mt-0.5 ml-2 hidden size-8 shrink-0 md:block lg:mt-0"
                />
              )}
            </div>
          </div>
          {isOpenPopup && (
            <DeletePopup
              onCancel={() => setIsOpenPopup(false)}
              onDeleteFavorite={() => handleFavourite?.(bank)}
            />
          )}
        </div>
        {['savings account', 'demand account'].includes(
          bank.productType.toLowerCase(),
        ) && (
          <div
            className={cn(
              'mt-3',
              bank.comment &&
                'flex flex-col divide-y divide-blue-100 border-t border-blue-100 lg:ml-[54px]',
            )}
          >
            {bank.comment &&
              textMutation(
                bank.comment,
                "body3 flex items-center pb-[12px] pt-[10px] font-normal tracking-[0.1px] before:mx-[7px] before:block before:h-[4px] before:w-[4px] before:shrink-0 before:rounded-full before:bg-blue-500 before:content-['']",
              )}
          </div>
        )}
      </div>
      {details && (
        <CardDetails
          isBlogBankCard={isBlogBankCard}
          bank={bank}
          isDetailsOpen={isDetailsOpen}
          toggleDetails={toggleDetails}
        />
      )}
    </div>
  );
};
