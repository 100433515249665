/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import { useEffect, useState } from 'react';

import { BadgeDeposit, BankImage, Icon } from '@/components';
import { DeletePopup } from '@/components/BankCard/parts';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { ShareButton } from '@/components/ShareButton/ShareButton';
import { useExternalRedirect } from '@/hooks/useExternalRedirect';
import type { IBankDepositCard } from '@/types';
import { cn, transformMonths } from '@/utils';
import { getFixedDepositsBreadcrumbs } from '@/utils/breadcrumbs';
import { COUNTRIES_PATHS } from '@/utils/countries';
import { getSlug } from '@/utils/getSlug';
import { textMutation } from '@/utils/textMutation';

import { ConfirmPopup } from '../ConfirmPopup';
import { ButtonsDeposit, DepositTerms } from './parts';

type ProductPageBankCardProps = {
  bank: IBankDepositCard;
  isPartnerBank?: boolean;
  onBackButtonClick: () => void;
  isFavourite?: boolean;
  handleFavourite?: (bank: IBankDepositCard) => void;
};

export const DepositHero: React.FC<ProductPageBankCardProps> = ({
  bank,
  onBackButtonClick,
  isFavourite,
  handleFavourite,
}) => {
  const { t } = useTranslation();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const { locale } = useRouter();

  // const supabase = useSupabaseClient();
  // const userID = useUserIdStore((state) => state.userID);
  const [confirmPopup, setConfirmPopup] = useState<boolean>(false);
  const { getRedirectUrl } = useExternalRedirect();
  // const useDeleteFavourite = (productIndex: number) =>
  //   useFavoriteStore((state) => state.deleteFavorite(productIndex));

  const { value, unit } = transformMonths(+bank.termMonths);

  // TODO: favourite for supabase request
  // const handleFavourite = async (deposit_id: IBankDepositCard) => {
  //   // if (!userID) return;
  //   setFavourite(!isFavourite);
  //   await upsertFavoritesDeposits(supabase, {
  //     favorite_deposit: [+deposit_id.productIndex],
  //     user_id: userID,
  //   })
  //     .then(() => setDeletePopup(false))
  //     .catch(() => {
  //       setDeletePopup(false);
  //     });
  //   // .then(() => useDeleteFavourite(deposit_id.productIndex));
  // };

  const handleFavoriteClick = () => {
    if (isFavourite) {
      setIsOpenPopup(true);
    } else {
      handleFavourite && handleFavourite(bank);
    }
  };
  useEffect(() => {
    if (!isFavourite) {
      setIsOpenPopup(false);
    }
  }, [isFavourite]);

  const renderBankInformation = () => {
    return (
      <div className="flex flex-col md:flex-row">
        <BankImage bankIndex={bank.bankIndex} className="mr-3 size-12" />
        <div className="mt-1 flex flex-col justify-center text-black md:mt-0">
          <p className="body1-bold text-lg capitalize leading-[1.35] text-blue-900 md:text-[17px] md:leading-[24px]">
            {bank.bankName}
          </p>
          <p className="body3 mt-[2px] text-grey-600 md:mt-0">
            {t(`database.countries.${bank.country}`)}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex max-w-full flex-col rounded-xl">
      <div className="mb-4 flex justify-between space-x-2 md:items-center">
        <Breadcrumbs breadcrumbs={getFixedDepositsBreadcrumbs()} />
        <div className="flex shrink-0 items-center gap-8">
          <div className="body3-bold hidden h-7 gap-2 md:flex">
            {bank.isPartnerBank && (
              <BadgeDeposit variant="available" className="flex items-center" />
            )}
            {bank.depositType === 'subordinated deposit' && (
              <BadgeDeposit
                variant="subordinated"
                className="flex items-center"
              />
            )}
            {bank.depositType.toLowerCase() === 'ordinary deposit' && (
              <BadgeDeposit variant="protected" className="flex items-center" />
            )}
          </div>
          <div className="flex items-center justify-center gap-2">
            <button
              type="button"
              onClick={handleFavoriteClick}
              className={cn('cursor-pointer')}
            >
              {isFavourite ? (
                <Icon name="button-fav-is-active" className="size-8" />
              ) : (
                <Icon name="button-to-fav" className="size-8" />
              )}
            </button>
            <ShareButton
              value={`/deposits/${bank.productIndex}`}
              className="size-8 shrink-0"
            />
          </div>
        </div>
      </div>

      {isOpenPopup && (
        <DeletePopup
          onCancel={() => setIsOpenPopup(false)}
          onDeleteFavorite={() => {
            handleFavourite ? handleFavourite(bank) : () => {};
          }}
        />
      )}

      <button
        type="button"
        className="absolute left-[-42px] top-[56px] hidden size-8 rounded-full xl:block"
      >
        <div onClick={onBackButtonClick}>
          <Icon name="shewronLeft" className="size-8 text-transparent" />
        </div>
      </button>

      <div>
        <h1 className="h3 md:h2 mb-9 tracking-[-0.64px] text-blue-900 sm:mb-4">
          {t('deposit-page.title', {
            bankName: bank.bankName,
            rate: bank.grossInterestRate,
            term: `${value} ${t(`deposit_card.${unit?.toLowerCase()}`)}`.toLowerCase(),
          })}
        </h1>
        <div className="flex max-w-[850px] flex-col gap-6">
          <div className="flex flex-col md:flex-row">
            {bank.bankSlug ? (
              <Link
                href={{
                  pathname: `/banks/${getSlug(COUNTRIES_PATHS[bank.bankSlugCountry][locale || 'en'])}/${bank.bankSlug}`,
                }}
              >
                {renderBankInformation()}
              </Link>
            ) : (
              renderBankInformation()
            )}
            <div className="body3-bold mt-[12px] flex gap-2 child:h-[20px] child:px-2 child:text-[11px] child:leading-[16px] md:hidden">
              {bank.isPartnerBank && <BadgeDeposit variant="available" />}
              {bank.depositType === 'subordinated deposit' && (
                <BadgeDeposit variant="subordinated" />
              )}
              {bank.depositType.toLowerCase() === 'ordinary deposit' && (
                <BadgeDeposit variant="protected" />
              )}
            </div>
          </div>

          {bank.comment && (
            <div className="flex flex-col gap-2">
              {textMutation(
                bank.comment,
                'body1 tracking-[0.6px] text-grey-600 first-letter:capitalize',
              )}
            </div>
          )}

          <DepositTerms bank={bank} />

          <ButtonsDeposit setConfirmPopup={setConfirmPopup} bank={bank} />
        </div>
      </div>

      {confirmPopup && (
        <ConfirmPopup
          confirmPopup={confirmPopup}
          source={getRedirectUrl(bank)}
          setConfirmPopup={setConfirmPopup}
        />
      )}
    </div>
  );
};
