/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { BankImage, Button, Container, Icon } from '@/components';
import { useExternalRedirect } from '@/hooks/useExternalRedirect';
import useUserInformation from '@/hooks/useUserInformation';
import { useAuthModalsControl, useAuthStore } from '@/store';
import { CURRENCY_SYMBOLS } from '@/store/storeSearch.consts';
import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { useShowModal } from '@/usecases/modals';
import {
  analyticServices,
  calcDepositTerms,
  checkIsNumber,
  cn,
  transformMonths,
} from '@/utils';
import { COUNTRIES_PATHS } from '@/utils/countries';
import { getSlug } from '@/utils/getSlug';

interface DepositHeroBarProps {
  bank: IBankDepositCard;
  isPartnerBank?: boolean;
  isFavourite?: boolean;
  handleFavourite?: (bank: IBankDepositCard) => void;
  isScrolledUp: boolean;
}

// TODO: add adaptive styles
export const DepositHeroBar: FC<DepositHeroBarProps> = ({
  bank,
  isFavourite,
  isScrolledUp,
  handleFavourite,
}) => {
  // const supabase = useSupabaseClient();
  // const userID = useUserIdStore((state) => state.userID);
  // const useDeleteFavourite = (productIndex: number) =>
  //   useFavoriteStore((state) => state.deleteFavorite(productIndex));
  const { value, unit } = transformMonths(+bank.termMonths);
  const { t } = useTranslation();
  // const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [, setIsOpenPopup] = useState(false);
  const router = useRouter();
  const { showQuestionOpenDepositModal } = useShowModal();
  const { features } = useUserInformation();

  const { redirectToExternalLink } = useExternalRedirect();

  const { token } = useAuthStore((state) => state);

  const { openAuthModal, setRedirectUri } = useAuthModalsControl(
    (state) => state,
  );

  // TODO: for favourite supabase request
  // const handleFavourite = async (deposit_id: IBankDepositCard) => {
  //   setFavourite(!isFavourite);
  //   await upsertFavoritesDeposits(supabase, {
  //     favorite_deposit: [+deposit_id.productIndex],
  //     user_id: userID,
  //   });
  //   // .then(() => useDeleteFavourite(deposit_id.productIndex));
  // };

  const { displayAmount, prefix } = calcDepositTerms(
    bank.minimumAmount,
    bank.maximumAmount,
  );

  const handleFavoriteClick = () => {
    if (isFavourite) {
      setIsOpenPopup(true);
    } else {
      handleFavourite && handleFavourite(bank);
    }
  };
  useEffect(() => {
    if (!isFavourite) {
      setIsOpenPopup(false);
    }
  }, [isFavourite]);

  const sendAnalytics = async (typeEvent: AnalyticsEvent) => {
    analyticServices.sendEvent({
      action: typeEvent,
      category: AnalyticsCategory.deposit,
      label: 'Deposit ID',
      payload: {
        bank_index: bank.bankIndex,
        product_index: bank.productIndex,
      },
    });
  };

  const handlePressOpenOnline = (e: any) => {
    e.preventDefault();
    sendAnalytics(AnalyticsEvent.openOnline);
    if ((token && !features.profile) || bank.partnerType !== 'platform') {
      redirectToExternalLink({
        onSuccess: () => {
          showQuestionOpenDepositModal({
            productIndex: bank.productIndex,
          });
        },
        bank,
      });
      return;
    }
    const uri = `/deposits/${bank.productIndex}/apply/`;
    if (!token) {
      openAuthModal('platform');
      setRedirectUri(uri);
      return;
    }
    router.push({
      pathname: uri,
    });
  };

  return (
    <div
      className={cn(
        'shadow-far transition-all duration-300 ease-in-out fixed inset-x-0 bottom-0 z-50 bg-white py-[13px] md:bottom-[unset] md:top-0',
        isScrolledUp && 'md:top-[60px] lg:top-20',
      )}
    >
      <Container className="relative flex">
        {bank.bankSlug ? (
          <Link
            href={{
              pathname: `/banks/${getSlug(COUNTRIES_PATHS[bank.bankSlugCountry][router.locale || 'en'])}/${bank.bankSlug}`,
            }}
          >
            <BankImage
              bankIndex={bank.bankIndex}
              className="my-auto mr-3 hidden shrink-0 md:block md:size-11 xl:size-14"
            />
          </Link>
        ) : (
          <BankImage
            bankIndex={bank.bankIndex}
            className="my-auto mr-3 hidden shrink-0 md:block md:size-11 xl:size-14"
          />
        )}
        <div className="hidden w-full flex-col md:flex md:justify-center">
          <h5 className="body1-bold truncate capitalize text-blue-900 md:max-w-[200px] lg:max-w-[400px] lg:leading-7 xl:max-w-[600px]">
            {bank.bankName}
          </h5>
          <div className="flex items-center">
            <p className="body3-bold text-gray-800">
              {Number(bank.grossInterestRate).toFixed(2)}%
            </p>
            <div className="mx-1.5 size-1 rounded-full bg-gray-500/50" />
            {Number(value) !== 0 ? (
              <p className="body3 text-gray-500">
                <span>{value}</span>
                <span>{t(`deposit_card.${unit?.toLowerCase()}`)}</span>
              </p>
            ) : null}
            {displayAmount && (
              <>
                <div className="mx-1.5 size-1 rounded-full bg-gray-500/50" />

                <p className="body3 text-gray-500">
                  {checkIsNumber(displayAmount) ? (
                    <>
                      <span>{t(prefix)}&nbsp;</span>
                      <span>
                        {CURRENCY_SYMBOLS[bank.currency.toLowerCase()]}
                        {displayAmount}
                      </span>
                    </>
                  ) : (
                    <span>{t(displayAmount)}</span>
                  )}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="flex w-full items-center gap-4 md:justify-end md:gap-[10px] lg:gap-4">
          <Link
            href={t('faq_page.question_1.link')}
            className={cn(
              'body2-bold hidden rounded-full tracking-[0.1px] md:flex md:items-center md:justify-center text-center md:h-11 md:min-w-[144px] md:leading-[18px] xl:h-14 xl:min-w-[160px] xl:leading-[20px] xl:tracking-[0.3px]',
              'bg-blue-100 text-blue-500 hover:bg-blue-150',
            )}
          >
            {t('deposit_card.button_how_to_choose')}
          </Link>
          {/* TODO: need to improve */}
          <Button
            className={cn(
              'body2-bold px-6 flex h-11 min-w-full content-center items-center xl:leading-[20px] leading-[18px] justify-center rounded-full md:min-w-[147px] xl:h-14 xl:min-w-[199px]',
              bank.isPartnerBank
                ? 'bg-blue-500 text-white hover:bg-blue-550'
                : 'bg-blue-100 text-blue-500 hover:bg-blue-150',
            )}
            props={{ onClick: handlePressOpenOnline }}
          >
            {bank.isPartnerBank
              ? t('deposit_card.button_open_online')
              : t('deposit_card.button_visit_banks_site')}
          </Button>
          <div className="relative flex size-8 items-center justify-center">
            <button
              type="button"
              onClick={handleFavoriteClick}
              className={cn('cursor-pointer')}
            >
              {isFavourite ? (
                <Icon name="button-fav-is-active" className="size-8" />
              ) : (
                <Icon name="button-to-fav" className="size-8" />
              )}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};
