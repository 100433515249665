import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';

import { Icon, SmartLink } from '@/components';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { useShowModal } from '@/usecases/modals';
import { analyticServices, cn } from '@/utils';

interface IConfirmPopupFooterProps {
  source: string;
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
}

export const ConfirmPopupFooter: FC<IConfirmPopupFooterProps> = ({
  source,
  setConfirmPopup,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { showQuestionOpenDepositModal } = useShowModal();

  const handleAnaluticsClick = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.visitBankSite,
      category: AnalyticsCategory.deposit,
      label: 'Deposit ID',
    });
    setConfirmPopup(false);
    showQuestionOpenDepositModal({
      productIndex: router.query.productIndex as string,
    });
  };
  return (
    <div className="flex flex-col items-center justify-center gap-4 md:flex-row">
      <SmartLink
        href={source}
        analyticsFunction={handleAnaluticsClick}
        className={cn(
          'body2-bold px-6 py-[18px] min-w-full md:min-w-[199px] rounded-full gap-1 flex justify-center items-center',
          'bg-blue-100 text-blue-500 hover:bg-blue-150',
        )}
      >
        {t('deposit_card.button_visit_banks_site')}
        <Icon
          name="rightArrow"
          className="size-3 -rotate-45 text-transparent"
        />
      </SmartLink>
    </div>
  );
};
