import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import Image from 'next/image';
import type { FC } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomOverlay } from '@/components/CustomOverlay';
import { SelectHeader } from '@/components/SearchBar/parts/SelectHeader';
import { useSearchStore } from '@/store';
import { COUNTRY_FLAGS, SEARCH_COUNTRIES } from '@/store/storeSearch.consts';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface ISelectCountryProps {
  className?: string;
  dropdownClassname?: string;
  isScrolling?: boolean;
  buttonClassName?: string;
  inputClassName?: string;
}

export const CountrySelect: FC<ISelectCountryProps> = ({
  className,
  dropdownClassname,
  isScrolling,
  buttonClassName,
  inputClassName,
}) => {
  const { t } = useTranslation();

  const { country: selectedCountry } = useSearchStore((state) => state);
  const setSelectedCountry = useSearchStore((state) => state.setCountry);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleCountryItemClick = (country: string) => {
    if (selectedCountry !== country) {
      setSelectedCountry(country);
    }
  };

  return (
    <div className={cn('relative flex-shrink-0 text-left', className)}>
      <DropdownMenuPrimitive.Root
        modal={isScrolling}
        onOpenChange={() => setOverlayVisible(!overlayVisible)}
      >
        {overlayVisible && <CustomOverlay />}
        <DropdownMenuPrimitive.Trigger
          className={cn(
            'w-full focus-visible:rounded-full focus-visible:outline-gray-200',
            inputClassName,
          )}
        >
          <SelectHeader
            onClick={() => {
              analyticServices.sendEvent({
                action: AnalyticsEvent.openResidency,
                category: AnalyticsCategory.deposit,
                label: 'openResidency',
              });
            }}
            label={t('deposits.residency')}
            imgSrc={COUNTRY_FLAGS[selectedCountry]!}
            imgAlt={selectedCountry}
            inFocus={overlayVisible}
            className={buttonClassName}
          >
            {t(`database.countries.${selectedCountry}`)}
          </SelectHeader>
        </DropdownMenuPrimitive.Trigger>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            side="bottom"
            align="start"
            sideOffset={9}
            className={cn(
              'shadow-far z-50 rounded-[31px] bg-white py-2',
              dropdownClassname,
            )}
          >
            <div className="custom-scroll mr-[10px] h-[310px] overflow-y-auto">
              <div className="px-2">
                {SEARCH_COUNTRIES.map((country: string) => (
                  <Fragment key={country}>
                    <DropdownMenuPrimitive.Item asChild>
                      <button
                        onClick={() => {
                          handleCountryItemClick(country);
                        }}
                        className={cn(
                          selectedCountry === country &&
                            'bg-grey-100 rounded-full',
                          'focus-visible:outline-gray-100 focus-visible:rounded-full flex w-full content-center items-center hover:rounded-full hover:bg-grey-100 justify-start px-4 pb-3 pt-[10px] gap-2 text-[15px] font-bold',
                        )}
                        type="button"
                      >
                        <Image
                          src={COUNTRY_FLAGS[country]!}
                          alt={country}
                          className="h-[15px] w-5"
                          width={20}
                          height={15}
                        />
                        <p className="md:truncate lg:disabled:truncate">
                          {t(`database.countries.${country}`)}
                        </p>
                      </button>
                    </DropdownMenuPrimitive.Item>
                  </Fragment>
                ))}
              </div>
            </div>
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};
