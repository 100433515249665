import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { type FC } from 'react';

import { Icon } from '@/components/Sprite';

export interface IViewButtonProps {
  href: string;
}

export const ViewButton: FC<IViewButtonProps> = ({ href }) => {
  const { t } = useTranslation();
  return (
    <Link
      href={{
        pathname: href,
      }}
      className="flex items-center justify-center gap-1 rounded-[32px] bg-blue-100 px-6 py-[13px] hover:bg-blue-150"
    >
      <span className="text-[13px] font-bold leading-[18px] text-blue-500">
        {t('deposit_card.button_view')}
      </span>
      <Icon name="rightArrow" className="size-4 text-transparent" />
    </Link>
  );
};
