import { PostLoginRequestError } from '@pickthebank/api-sdk/request/auth/post-login.request';
import error from 'next/error';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import { Button, CheckboxInput } from '@/components';
import { getFormContextByType } from '@/components/Dialog/parts/SignUpForm/singUpForm.utils';
import { useAnalytics } from '@/contexts/AnalyticsContext';
import { useApi } from '@/contexts/ApiContext';
import { signUp } from '@/services/supabase';
import { useAuthModalsControl, useAuthStore } from '@/store';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, capitalizeFirstLetter } from '@/utils';
import { createArrayWithActiveSubscriptions } from '@/utils/subscriptions';

import { DialogTerms } from './parts';

interface ISignUpFormProps {
  onSubmit: () => void;
  executeCaptcha: () => Promise<string>;
}

export const SignUpForm: FC<ISignUpFormProps> = ({
  onSubmit,
  executeCaptcha,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const {
    setEmail,
    errors,
    setError,
    token: userToken,
    email,
  } = useAuthStore((state) => state);
  const inputRef = useRef<HTMLInputElement>(null);
  const [emailField, setEmailField] = useState(userToken ? email : '');

  const { analytics } = useAnalytics();

  const { type, setCloseModal, redirectUri } = useAuthModalsControl(
    (state) => state,
  );
  const { description, button } = getFormContextByType(type);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { putProfile } = useApi();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      const subs =
        isSubscribed || type === 'subscribe'
          ? createArrayWithActiveSubscriptions()
          : [];
      if (userToken) {
        await putProfile({ subscriptions: subs });
        setCloseModal();
      } else {
        const token = await executeCaptcha();
        await signUp(
          emailField,
          router.locale || 'en',
          token,
          analytics,
          subs,
          redirectUri || undefined,
        );
      }
      analyticServices.sendEvent({
        action: AnalyticsEvent.authModalEmailSubmitted,
        label: AnalyticsCategory.auth,
      });
      onSubmit();
      setEmail(emailField);
    } catch (err: any) {
      if (
        err.response.data.message ===
        PostLoginRequestError.VERIFICATION_MESSAGE_HAS_SENT
      ) {
        setEmail(emailField);
      }
      setError([...err.response.data.message]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (errors.length > 0) {
      setError([]);
    }
    setEmailField(e.target.value);
  };

  useEffect(() => {
    setError([]);
  }, []);

  return (
    <>
      <div
        className="mt-4 flex w-full content-center justify-center gap-4 md:mt-6"
        onClick={(e) => e.stopPropagation()}
      >
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <p className="body2 mb-4 text-grey-800">{t(description)}</p>
          <div className="relative w-full">
            <input
              ref={inputRef}
              value={emailField}
              onChange={handleChange}
              disabled={!!userToken}
              type="email"
              name="email"
              id="email"
              className="input-field body3 md:body2 h-[44px] w-full rounded-[31px] border-[1.25px] border-grey-200 px-5 disabled:cursor-not-allowed disabled:bg-grey-300 disabled:text-grey-600 md:h-[56px] md:px-7"
              placeholder={t('login.subscribe.placeholder_email_text')}
            />
          </div>
          <Button
            color="blue"
            className="mt-2 h-[44px] w-full text-[13px] sm:h-[56px] md:text-[15px]"
            props={{
              onClick: handleSubmit,
              disabled: !emailField || error.length > 0,
            }}
          >
            {t(button)}
          </Button>
          {errors.length > 0 &&
            errors.map((err) => (
              <span
                key={err}
                className="text-[11px] font-medium leading-[16px] tracking-[0.2px] text-red-500"
              >
                {capitalizeFirstLetter(err)}
              </span>
            ))}
          {type !== 'subscribe' && (
            <CheckboxInput
              label="I agree to receive monthly deposit market reviews from PickTheBank (you can unsubscribe later)"
              checked={isSubscribed}
              onChange={setIsSubscribed}
              className="mt-4 items-start"
              checkboxClassName="mt-1.5 h-5 w-5 shrink-0"
              labelClassName="ml-2 text-[13px] font-medium tracking-[0.1px]"
            />
          )}
        </form>
      </div>
      <DialogTerms />
    </>
  );
};
