import { marked } from 'marked';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import React, { type FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  Container,
  DepositsCardList,
  LazyLoaded,
  LogoClouds,
  NewsLetter,
  NoDepositFound,
  SearchBar,
} from '@/components';
import { HeroDeposits } from '@/components/HeroDeposits';
import { DescriptionOfModal } from '@/components/SearchBar/parts';
import { useApi } from '@/contexts/ApiContext';
import { useCmsContent } from '@/contexts/CmsContentContext';
import { useDepositsLayout } from '@/layouts/DepositsLayout/useDepositsLayout';
import BlockWithTitle from '@/layouts/FixedGermanDeposits/parts/BlockWithTitle';
import { MapDepositsFromServer } from '@/services/supabase';
import { useSearchStore } from '@/store';
import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { cn, LIST_COUNTRIES } from '@/utils';
import { analyticServices } from '@/utils/analytics';
import { calculateHighestRate } from '@/utils/calculateHighestRate';
import type { ProductsMeta } from '@/utils/products';

export interface IDepositsProps {
  initialPartnerDeposits: IBankDepositCard[];
  initialPartnerDepositsMeta: ProductsMeta;
  initialDeposits: IBankDepositCard[];
  initialDepositsMeta: ProductsMeta;
  setHighestRate?: (rate: number) => void;
  h1: string;
  sections?: any[];
}

export const DepositsLayout: FC<IDepositsProps> = ({
  initialPartnerDeposits,
  initialPartnerDepositsMeta,
  initialDeposits,
  initialDepositsMeta,
  // isScrolledDown = false,
  // isScrolledUp = false,
  setHighestRate,
  h1,
  sections,
}) => {
  const router = useRouter();
  const { seoContent } = useCmsContent();
  const [partnerDeposits, setPartnerDeposits] = useState(
    initialPartnerDeposits,
  );
  const [partnerDepositsMeta, setPartnerDepositsMeta] = useState(
    initialPartnerDepositsMeta,
  );
  const [deposits, setDeposits] = useState(initialDeposits);
  const [depositsMeta, setDepositsMeta] = useState(initialDepositsMeta);
  const isMobile = useMediaQuery({
    query: '(max-width: 728px)',
  });
  const api = useApi();

  const { country, currency, terms, setIsLoading } = useSearchStore(
    (state) => state,
  );

  const { updateUrlWithQuery, syncUrlWithStore } = useDepositsLayout();

  const fetchDeposits = async () => {
    setIsLoading(true);

    const preparedCountry = LIST_COUNTRIES.find(
      (i) => i.name.toLowerCase() === country?.toLowerCase(),
    );

    if (!preparedCountry) return;

    const depTerms = terms.filter((op) => op.selected).map((op) => op.value);

    // const depositTypes = depTypes.length > 0 ? depTypes : undefined;
    const depositTerms = depTerms.length > 0 ? depTerms : undefined;

    const { data: accounts, meta: accountsMeta } =
      await api.getDepositsListRequest('en', {
        page: 1,
        product_types: ['fixed term deposit'],
        deposit_types: ['ordinary deposit'],
        is_partner: false,
        countries: [preparedCountry.iso2],
        terms: depositTerms,
        currencies: currency === 'all' ? undefined : [currency],
      });
    setDepositsMeta(accountsMeta);

    const preparedAccounts: IBankDepositCard[] = accounts?.map((deposit: any) =>
      MapDepositsFromServer(deposit),
    )!;
    setDeposits(preparedAccounts);

    const { data: partnerAccounts, meta: partnerAccountsMeta } =
      await api.getDepositsListRequest('en', {
        page: 1,
        product_types: ['fixed term deposit'],
        deposit_types: ['ordinary deposit'],
        is_partner: true,
        terms: depositTerms,
        countries: [preparedCountry.iso2],
        currencies: currency === 'all' ? undefined : [currency],
      });

    setPartnerDepositsMeta(partnerAccountsMeta);

    const preparedPartnerAccounts: IBankDepositCard[] = partnerAccounts?.map(
      (deposit: any) => MapDepositsFromServer(deposit),
    )!;
    setPartnerDeposits(preparedPartnerAccounts);

    if (setHighestRate) {
      setHighestRate(
        calculateHighestRate(
          preparedAccounts[0]?.grossInterestRate,
          preparedPartnerAccounts[0]?.grossInterestRate,
        ) || 0,
      );
    }

    setIsLoading(false);
  };

  const onLoadMoreSavingsAccounts = async () => {
    try {
      const preparedCountry = LIST_COUNTRIES.find(
        (i) => i.name.toLowerCase() === country?.toLowerCase(),
      );
      if (!preparedCountry) return;
      const depTerms = terms.filter((op) => op.selected).map((op) => op.value);

      // const depositTypes = depTypes.length > 0 ? depTypes : undefined;
      const depositTerms = depTerms.length > 0 ? depTerms : undefined;
      const { data: accounts, meta: accountsMeta } =
        await api.getDepositsListRequest('en', {
          page: depositsMeta.page + 1,
          product_types: ['fixed term deposit'],
          deposit_types: ['ordinary deposit'],
          is_partner: false,
          terms: depositTerms,
          countries: [preparedCountry.iso2],
          currencies: currency === 'all' ? undefined : [currency],
        });
      setDepositsMeta(accountsMeta);

      const preparedAccounts: IBankDepositCard[] = accounts?.map(
        (deposit: any) => MapDepositsFromServer(deposit),
      )!;
      setDeposits([...deposits, ...preparedAccounts]);
    } catch (e) {
      console.log(e);
    }
  };

  const onLoadMorePartnerSavingsAccounts = async () => {
    try {
      const preparedCountry = LIST_COUNTRIES.find(
        (i) => i.name.toLowerCase() === country?.toLowerCase(),
      );
      if (!preparedCountry) return;
      const depTerms = terms.filter((op) => op.selected).map((op) => op.value);

      // const depositTypes = depTypes.length > 0 ? depTypes : undefined;
      const depositTerms = depTerms.length > 0 ? depTerms : undefined;
      const { data: accounts, meta: accountsMeta } =
        await api.getDepositsListRequest('en', {
          page: partnerDepositsMeta.page + 1,
          product_types: ['fixed term deposit'],
          deposit_types: ['ordinary deposit'],
          is_partner: true,
          terms: depositTerms,
          countries: [preparedCountry.iso2],
          currencies: currency === 'all' ? undefined : [currency],
        });
      setPartnerDepositsMeta(accountsMeta);

      const preparedAccounts: IBankDepositCard[] = accounts?.map(
        (deposit: any) => MapDepositsFromServer(deposit),
      )!;
      setPartnerDeposits([...partnerDeposits, ...preparedAccounts]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnSubmit = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickFindDepositButton,
      category: AnalyticsCategory.deposit,
      label: 'clickFindDepositButton',
    });

    updateUrlWithQuery('/fixed-deposits');
    fetchDeposits();
  };

  useEffect(() => {
    syncUrlWithStore();
  }, []);

  useEffect(() => {
    setDeposits(initialDeposits);
    setPartnerDeposits(initialPartnerDeposits);
    setDepositsMeta(initialDepositsMeta);
    setPartnerDepositsMeta(initialPartnerDepositsMeta);
    syncUrlWithStore();
  }, [
    initialDeposits,
    initialDepositsMeta,
    initialPartnerDeposits,
    initialPartnerDepositsMeta,
  ]);

  const { t } = useTranslation();

  return (
    <div className={cn('z-10 flex h-full flex-col rounded-lg')}>
      <div className="bg-blue-100">
        <HeroDeposits
          h1={h1}
          advantages={[
            t('fixed-deposits.advantage.first'),
            t('fixed-deposits.advantage.second'),
            t('fixed-deposits.advantage.third', {
              percentage:
                calculateHighestRate(
                  deposits[0]?.grossInterestRate,
                  partnerDeposits[0]?.grossInterestRate,
                ) || 0,
            }),
          ]}
        />
      </div>
      <div className="md:shadow-far z-10 mx-auto -mt-12 min-h-[112px] w-full shrink-0 rounded-2xl px-5 py-6 sm:max-w-[500px] md:w-[660px] md:max-w-none md:bg-white md:p-6 lg:w-[888px] xl:w-[1140px]">
        <SearchBar
          isHomePage
          isScrolling={false}
          className="shadow-far"
          onSubmit={handleOnSubmit}
        />
        <div className="shadow-far z-10 mx-auto -mt-12 flex w-full flex-col gap-2 rounded-2xl bg-white py-6 md:hidden">
          {isMobile && (
            <LazyLoaded>
              <DescriptionOfModal buttonChange onSubmit={handleOnSubmit} />
            </LazyLoaded>
          )}
        </div>
        {/* TODO: For future use */}
        {/* <SearchExtend className="mt-2" /> */}
      </div>
      <div className="mt-7 flex w-full border-b">
        <Container className="z-[1]">
          <LogoClouds />
        </Container>
      </div>
      <div className="relative mb-4 pb-20 before:absolute before:inset-0 before:bg-blue-50 xl:before:inset-x-4 xl:before:rounded-2xl">
        <Container className="relative">
          {deposits.length === 0 && partnerDeposits.length === 0 ? (
            <NoDepositFound />
          ) : (
            <div className="z-10 flex h-full flex-col rounded-lg">
              <DepositsCardList
                featured={partnerDeposits}
                allDeposits={deposits}
                onLoadMoreDeposits={onLoadMoreSavingsAccounts}
                onLoadMoreFeaturedDeposits={onLoadMorePartnerSavingsAccounts}
                shouldRenderH1
                shouldLoadMoreFeatured={
                  Math.ceil(
                    partnerDepositsMeta.total / partnerDepositsMeta.count,
                  ) > partnerDepositsMeta.page
                }
                shouldLoadMore={
                  Math.ceil(depositsMeta.total / depositsMeta.count) >
                  depositsMeta.page
                }
                // favoriteDeposits={favoriteDeposits}
              />
            </div>
          )}
        </Container>
      </div>
      {sections && !seoContent?.mainText && (
        <div className="seo-content">
          <Container className="mt-12 max-w-[850px] px-5 lg:px-0">
            <BlockWithTitle
              title={t('german_deposits.about')}
              className="mb-12 pt-4"
              titleClassName="text-[32px] font-bold mb-7 tracking-[0.8px]"
            >
              <ul>
                {sections.map((section) => (
                  <li key={section.title}>
                    <Link
                      href={`${router.asPath.split('#')[0]}#${section.title}`}
                    >
                      {section.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </BlockWithTitle>
            {sections.map((section) => (
              <BlockWithTitle
                key={section.title}
                title={section.title}
                className="mb-16 last:mb-20"
              >
                {section.content}
              </BlockWithTitle>
            ))}
          </Container>
        </div>
      )}
      {seoContent?.mainText && (
        <Container className="my-12 max-w-[850px] px-5 lg:px-0">
          <div
            dangerouslySetInnerHTML={{
              __html: marked(seoContent.mainText),
            }}
            className="markdown-article flex flex-col gap-4 md:mx-[34px] lg:mx-auto lg:flex lg:w-full lg:max-w-[849px] lg:flex-col"
          />
        </Container>
      )}
      <NewsLetter />
    </div>
  );
};
