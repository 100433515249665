import { useTranslation } from 'next-i18next';

export const useHeader = () => {
  const { t } = useTranslation();
  const links = [
    {
      title: t('header.all_deposits'),
      path: '/fixed-deposits',
    },
    {
      title: t('header.savings-accounts'),
      path: '/savings-accounts',
    },
    {
      title: t('header.blog'),
      path: '/blog',
    },
    {
      title: t('header.statistics'),
      path: '/statistics/maximum-interest-rates-in-different-countries',
    },
    {
      title: t('header.banks'),
      path: '/banks',
    },
    {
      title: t('header.multibanking-platform'),
      path: '/multibanking-platform',
    },
  ];
  return {
    links,
  };
};
