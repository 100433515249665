import Image from 'next/image';
import Link from 'next-translate-routes/link';
import router from 'next-translate-routes/router';
import type { FC } from 'react';

import { Icon } from '@/components/Sprite';
import { useCmsContent } from '@/contexts/CmsContentContext';
import type { ILocalization } from '@/store/storeI18n';
import { useI18nStore } from '@/store/storeI18n';
import { cn } from '@/utils';

interface ILanguageMenuProps {
  onCloseLauguageMenu: () => void;
  onCloseAll: () => void;
}

// TODO: DEPRACETED: The LanguageMenu scenario needs to be updated
export const LanguageMenu: FC<ILanguageMenuProps> = ({
  onCloseAll,
  onCloseLauguageMenu,
}) => {
  const localizations = useI18nStore((state) => state.localizations);
  const { preparedLocalizations } = useCmsContent();

  return (
    <div className="safe-area fixed left-0 top-0 z-[51] !h-dvh !w-screen bg-white">
      <div className="flex max-h-[60px] min-h-[60px] content-center items-center justify-between border border-grey-200 px-5">
        <div onClick={onCloseLauguageMenu}>
          <Icon className="size-6 cursor-pointer text-3xl" name="shewronLeft" />
        </div>
        <p className="body1 text-blue-900">Language</p>
        <div className="flex size-8 lg:hidden" />
      </div>
      <ul className="mx-5 flex h-[calc(100dvh-60px)] flex-col divide-y divide-grey-200 overflow-y-auto border-b border-b-grey-200">
        {Object.entries(localizations).map(
          ([key, value]: [string, ILocalization]) => (
            <li key={key} className="w-full border-b-grey-200 px-2 py-5">
              <Link
                key={key}
                href={{
                  pathname:
                    value.link !== '/' && !value.notSupported
                      ? value.link
                      : preparedLocalizations[key]?.href || '/',
                }}
                locale={value.locale}
                onClick={onCloseAll}
                className={cn(
                  'flex content-center items-center justify-between',
                  (preparedLocalizations[key]?.href === '/' ||
                    !preparedLocalizations[key]?.href) &&
                    value.notSupported &&
                    'opacity-40 hover:opacity-100',
                  router.locale === key && 'pointer-events-none',
                )}
              >
                <div className="flex content-center items-center">
                  <Image
                    className="mr-2 h-3 w-4"
                    src={value.flagImg}
                    alt={value.title}
                  />
                  <span
                    className={cn(
                      'text-blue-900',
                      router.locale === key ? 'body2-bold' : 'body2',
                    )}
                  >{`${value.title} (${value.shortName})`}</span>
                </div>
                {router.locale === key && (
                  <Icon
                    name="Tik"
                    className="ml-auto size-5 text-transparent"
                  />
                )}
              </Link>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};
