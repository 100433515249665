import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import React, { useEffect, useState } from 'react';

import { Icon } from '@/components';
import { fetchFilteredBlogItems } from '@/services/supabase';
import { cn, getFlag } from '@/utils';

interface ICountry {
  name: string;
  code: string;
}

export const BlogNavBar = () => {
  const router = useRouter();

  const { t } = useTranslation();
  const regionNames = new Intl.DisplayNames(['en'], {
    type: 'region',
  });

  const [countries, setCountries] = useState([] as ICountry[]);
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  const isShowAllVisible = countries.length > 3; // TODO: need to improve
  const visibleCountries = countries.slice(0, 3);
  const showMoreCountries = countries.slice(3);

  const toggleDetails = () => {
    setDetailsOpen(!isDetailsOpen);
  };

  const handleNavItemClick = (code: string) => {
    if (code === t('global.recent')) {
      router.push({ pathname: '/blog', query: {} });
    } else {
      router.push({ pathname: '/blog', query: { country: code } });
    }
  };

  const DisclosureButton = ({ textButton }: { textButton: string }) => {
    return (
      <Disclosure.Button
        onClick={toggleDetails}
        className="hidden size-full cursor-pointer items-center gap-3 py-[18px] lg:flex"
      >
        {isDetailsOpen ? (
          <ChevronUpIcon className="size-4 stroke-[2px] text-grey-800" />
        ) : (
          <Icon className="size-4 text-transparent" name="shewronDown" />
        )}
        <p className="body2 tracking-[0.2px] text-grey-800">{textButton}</p>
      </Disclosure.Button>
    );
  };

  useEffect(() => {
    const getByContry = async () => {
      const { data } = await fetchFilteredBlogItems({
        field: 'Country',
      });

      const uniqueCountries: ICountry[] = data
        .filter((item: { attributes: { Country: string } }) =>
          Boolean(item.attributes.Country),
        )
        .map(
          (item: { attributes: { Country: string } }) =>
            item.attributes.Country,
        )
        .filter(
          (value: string, index: number, array: any[]) =>
            array.indexOf(value) === index,
        )
        .map((item: string) => {
          return {
            code: item,
            name: regionNames.of(item)!,
          };
        });

      setCountries(uniqueCountries);
    };
    getByContry();
  }, []);

  return (
    <ul className="flex gap-8 lg:mt-[72px] lg:flex-col lg:gap-0 lg:divide-y lg:divide-grey-200">
      {/* TODO: uncomment after beckend fixes */}
      {/* <li
        className={cn(
          'relative min-w-fit lg:border-t',
          selectedItem === t('global.trending') &&
            'after:absolute after:bottom-0 after:h-[3px] after:w-full after:rounded-[2px] after:bg-blue-500 lg:after:hidden',
        )}
      >
        <button
          type="button"
          onClick={handleNavItemClick}
          className="flex h-full w-full flex-none items-center gap-1 py-[18px] pl-0.5 lg:gap-2"
        >
          <Icon className="h-4 w-4 text-transparent" name="fire" />
          <p
            className={cn(
              'body2 tracking-[0.2px] text-grey-800',
              selectedItem === t('global.trending') && 'lg:font-bold',
            )}
          >
            {t('global.trending')}
          </p>
        </button>
      </li> */}

      <li
        className={cn(
          'relative min-w-fit',
          !router.query.country &&
            'after:absolute after:bottom-0 after:h-[3px] after:w-full after:rounded-[2px] after:bg-blue-500 lg:after:hidden',
        )}
      >
        <button
          type="button"
          onClick={() => {
            handleNavItemClick(t('global.recent'));
          }}
          className="flex size-full flex-none items-center gap-1 py-[18px] pl-0.5 lg:gap-2"
        >
          <Icon className="size-4 text-transparent" name="recentBlack" />
          <p
            className={cn(
              'body2 tracking-[0.2px] text-grey-800',
              !router.query.country && 'lg:font-bold',
            )}
          >
            {t('global.recent')}
          </p>
        </button>
      </li>
      {visibleCountries.map((country, index) => {
        return (
          <li
            key={index}
            className={cn(
              'relative min-w-fit',
              router.query.country &&
                regionNames.of(router.query.country as string) ===
                  country.name &&
                'after:absolute after:bottom-0 after:h-[3px] after:w-full after:rounded-[2px] after:bg-blue-500 lg:after:hidden',
            )}
          >
            <button
              type="button"
              onClick={() => {
                handleNavItemClick(country.code);
              }}
              className="flex size-full flex-none items-center gap-1 py-[18px] lg:gap-2 lg:pl-0.5"
            >
              <Image
                src={getFlag(country.code)}
                alt={country.name}
                className="h-[15px] w-5 shrink-0 rounded-sm"
                width={20}
                height={15}
              />
              <p
                className={cn(
                  'body2 tracking-[0.2px] text-grey-800 truncate',
                  router.query.country &&
                    regionNames.of(router.query.country as string) ===
                      country.name &&
                    'lg:font-bold',
                )}
              >
                {t(`database.countries.${country.name}`)}
              </p>
            </button>
          </li>
        );
      })}

      {isShowAllVisible && (
        <li className="ml-auto flex min-w-fit lg:hidden">
          <Dropdown.Root>
            <Dropdown.Trigger asChild>
              <button
                className="flex cursor-pointer items-center gap-0.5 outline-none"
                type="button"
              >
                <p className="body2 tracking-[0.2px] text-grey-800">
                  {t(`global.more_categories`)}
                </p>
                <ChevronDownIcon className="size-4 stroke-[2px] text-grey-800" />
              </button>
            </Dropdown.Trigger>

            <Dropdown.Portal>
              <Dropdown.Content
                className="z-20 flex w-[160px] flex-col gap-1 rounded-md bg-white p-2 shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]"
                sideOffset={4}
              >
                {showMoreCountries.map((country, index) => {
                  return (
                    <Dropdown.Item
                      asChild
                      key={index}
                      className="body2 z-20 flex h-7 cursor-pointer items-center rounded-[3px] p-2 tracking-[0.2px] text-grey-800 outline-none transition data-[highlighted]:bg-slate-100"
                    >
                      <button
                        onClick={() => {
                          handleNavItemClick(country.code);
                        }}
                        type="button"
                      >
                        {t(`database.countries.${country.name}`)}
                      </button>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Content>
            </Dropdown.Portal>
          </Dropdown.Root>
        </li>
      )}

      {isShowAllVisible && (
        <Disclosure as="li" className="ml-auto hidden lg:ml-0 lg:block">
          <Transition show={isDetailsOpen}>
            <Disclosure.Panel
              static
              className="border-b lg:divide-y lg:divide-grey-200"
            >
              {showMoreCountries.map((country, index) => {
                return (
                  <li
                    key={index}
                    className={cn(
                      'relative',
                      router.query.country &&
                        regionNames.of(router.query.country as string) ===
                          country.name &&
                        'md:after:absolute md:after:bottom-0 md:after:h-[3px] md:after:w-full md:after:rounded-[2px] md:after:bg-blue-500 lg:after:hidden',
                    )}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        handleNavItemClick(country.code);
                      }}
                      className="flex size-full items-center gap-1 py-[18px] lg:gap-2 lg:pl-0.5"
                    >
                      <Image
                        src={getFlag(country.code)}
                        alt={country.name}
                        className="h-3 w-4 rounded-sm"
                        width={4}
                        height={3}
                        unoptimized
                      />
                      <p
                        className={cn(
                          'body2 tracking-[0.2px] text-grey-800',
                          router.query.country &&
                            regionNames.of(router.query.country as string) ===
                              country.name &&
                            'lg:font-bold',
                        )}
                      >
                        {t(`database.countries.${country.name}`)}
                      </p>
                    </button>
                  </li>
                );
              })}
            </Disclosure.Panel>
          </Transition>
          <li>
            <DisclosureButton
              textButton={
                isDetailsOpen ? t('global.show_less') : t('global.show_all')
              }
            />
          </li>
        </Disclosure>
      )}
    </ul>
  );
};
