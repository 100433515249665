import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React, { useState } from 'react';

import { Button } from '@/components';
import RadioGroup from '@/components/Utils/RadioButton/RadioGroup';
import { useAnalytics } from '@/contexts/AnalyticsContext';
import { useModal } from '@/contexts/ModalContext';
import { postQuestionOpenDepositRequest } from '@/services/supabase';
import { useAuthStore } from '@/store';

const OTHER_TEXT_MAX_LENGTH = 256;

export interface QuestionOpenDepositProps {
  productIndex?: string;
}
export const QuestionOpenDeposit: FC<QuestionOpenDepositProps> = ({
  productIndex,
}) => {
  const { email } = useAuthStore((state) => state);
  const { t, i18n } = useTranslation();
  const { closeModal } = useModal();
  const { analytics } = useAnalytics();

  const [selectedOption, setSelectedOption] = useState<string>('option1');
  const [otherText, setOtherText] = useState<string>('');

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setOtherText(event.target.value);
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await postQuestionOpenDepositRequest({
        answer: selectedOption,
        otherText: otherText || undefined,
        email: email || undefined,
        productIndex,
        locale: i18n.language,
        analytics,
      });
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const radioOptions = [
    {
      label: t('open_deposit_modal.yes_i_did'),
      value: t('open_deposit_modal.yes_i_did', { lng: 'en' }),
    },
    {
      label: t('open_deposit_modal.not_satisfied'),
      value: t('open_deposit_modal.not_satisfied', { lng: 'en' }),
    },
    {
      label: t('open_deposit_modal.unavailable'),
      value: t('open_deposit_modal.unavailable', { lng: 'en' }),
    },
    {
      label: t('open_deposit_modal.too_complicated'),
      value: t('open_deposit_modal.too_complicated', { lng: 'en' }),
    },
    {
      label: t('open_deposit_modal.later'),
      value: t('open_deposit_modal.later', { lng: 'en' }),
    },
    {
      label: t('open_deposit_modal.other'),
      value: t('open_deposit_modal.other', { lng: 'en' }),
    },
  ];

  return (
    <div className="flex w-[452px] max-w-[500px] flex-col">
      <form onSubmit={handleSubmit}>
        <div className="flex items-start">
          <RadioGroup
            options={radioOptions}
            selectedOption={selectedOption}
            onChange={handleOptionChange}
            className="flex flex-col gap-4"
          />
        </div>
        {selectedOption === t('open_deposit_modal.other', { lng: 'en' }) && (
          <div>
            <p className="body2-bold mt-4 text-blue-900">
              {t('open_deposit_modal.other_input_heading')}
            </p>
            <textarea
              placeholder={t('open_deposit_modal.other_input_placeholder')}
              maxLength={OTHER_TEXT_MAX_LENGTH}
              onChange={handleTextareaChange}
              className="body2 mt-2 min-h-[96px] w-full resize-none rounded-xl border border-grey-200 px-4 py-[18px] text-blue-900 placeholder:text-grey-500"
            />
          </div>
        )}
        <Button
          className="mt-4 w-full py-[18px]"
          color="blue"
          props={{ type: 'submit' }}
        >
          {t('global.submit')}
        </Button>
      </form>
    </div>
  );
};
