import * as Dialog from '@radix-ui/react-dialog';
import Image from 'next/image';
import type { FC } from 'react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileModals } from '@/components/MobileModal';
import { Icon } from '@/components/Sprite';
import { useSearchStore } from '@/store';
import { COUNTRY_FLAGS, SEARCH_COUNTRIES } from '@/store/storeSearch.consts';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface IModalCountryProps {
  className?: string;
  countryModalSearch?: boolean;
  buttonClassName?: string;
}

export const CountryModal: FC<IModalCountryProps> = ({
  className,
  countryModalSearch,
  buttonClassName,
}) => {
  const { t } = useTranslation();
  const selectedCountry = useSearchStore((state) => state.country);
  const setSelectedCountry = useSearchStore((state) => state.setCountry);

  const handleCountryItemClick = (country: string) => {
    if (selectedCountry === country) return;
    setSelectedCountry(country);
  };

  const sendAnalyticEvent = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.openResidency,
      category: AnalyticsCategory.deposit,
      label: 'openResidency',
    });
  };

  return (
    <MobileModals
      className={className}
      zMoreIndex
      title={`${t('search.country')}`}
      trigger={
        <button
          className={cn(
            'search-input w-full border-0 pr-[20px]',
            countryModalSearch && 'pb-[14px] pl-[24px] pr-[12px] pt-[10px]',
            buttonClassName,
          )}
          onClick={sendAnalyticEvent}
          type="button"
        >
          <p className="caption whitespace-nowrap text-left">
            {t('deposits.residency')}
          </p>
          <div
            className={cn(
              'flex w-full items-end justify-between',
              countryModalSearch && 'gap-2',
            )}
          >
            <div className="body2-bold flex min-w-0 grow items-center justify-start gap-2 rounded-md font-bold text-blue-900">
              <Image
                src={COUNTRY_FLAGS[selectedCountry]!}
                alt={selectedCountry}
                className="h-auto w-5 rounded-sm"
                width="32"
                height="24"
                unoptimized
              />
              <p className="truncate text-[15px]">
                {t(`database.countries.${selectedCountry}`)}
              </p>
            </div>
            <Icon
              className="min-w-0 shrink-0 stroke-2 text-transparent"
              name="arrowDownIcon"
            />
          </div>
        </button>
      }
    >
      <div className="flex flex-col justify-items-stretch gap-y-2">
        {SEARCH_COUNTRIES.map((country: string) => (
          <Fragment key={country}>
            <Dialog.Close asChild>
              <button
                onClick={() => handleCountryItemClick(country)}
                className={cn(
                  selectedCountry === country
                    ? 'rounded-full'
                    : 'text-grayColor',
                  'flex w-full content-center items-center justify-start gap-2 py-3 text-[15px] font-bold',
                )}
                type="button"
              >
                <Image
                  src={COUNTRY_FLAGS[country]!}
                  alt={country}
                  className="h-[15px] w-5"
                  width={20}
                  height={15}
                />
                <span
                  className={cn(
                    'text-blue-900 tracking-[0.2px]',
                    selectedCountry === country ? 'body2-bold' : 'body2',
                  )}
                >
                  {t(`database.countries.${country}`)}
                </span>
              </button>
            </Dialog.Close>
            <div className="h-px w-full bg-gray-200" />
          </Fragment>
        ))}
      </div>
    </MobileModals>
  );
};
