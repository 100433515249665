export const faqQuestion = [
  {
    question: 'faq_page.question_1.title',
    answer: 'faq_page.question_1.description.text',
    listAnswers: [
      'faq_page.question_1.description.point_1',
      'faq_page.question_1.description.point_2',
      'faq_page.question_1.description.point_3',
      'faq_page.question_1.description.point_4',
      'faq_page.question_1.description.point_5',
      'faq_page.question_1.description.point_6',
      'faq_page.question_1.description.point_7',
    ],
    link: 'faq_page.question_1.link',
  },
  {
    question: 'faq_page.question_2.title',
    answer: 'faq_page.question_2.description',
    listAnswers: [],
    link: 'faq_page.question_2.link',
  },
  {
    question: 'faq_page.question_3.title',
    answer: 'faq_page.question_3.description.text',
    listAnswers: [
      'faq_page.question_3.description.point_1',
      'faq_page.question_3.description.point_2',
      'faq_page.question_3.description.point_3',
      'faq_page.question_3.description.point_4',
    ],
    link: 'faq_page.question_3.link',
  },
  {
    question: 'faq_page.question_4.title',
    answer: '',
    listAnswers: [
      'faq_page.question_4.description.point_1',
      'faq_page.question_4.description.point_2',
      'faq_page.question_4.description.point_3',
      'faq_page.question_4.description.point_4',
      'faq_page.question_4.description.point_5',
    ],
    link: 'faq_page.question_4.link',
  },
  {
    question: 'faq_page.question_5.title',
    answer: '',
    listAnswers: [
      'faq_page.question_5.description.point_1',
      'faq_page.question_5.description.point_2',
      'faq_page.question_5.description.point_3',
      'faq_page.question_5.description.point_4',
      'faq_page.question_5.description.point_5',
    ],
    link: 'faq_page.question_5.link',
  },
];

export const partnerDepositFAQ = [
  {
    question: 'partners_faq.question_1.question',
    answer: '',
    listAnswers: [
      'partners_faq.question_1.point1',
      'partners_faq.question_1.point2',
      'partners_faq.question_1.point3',
      'partners_faq.question_1.point4',
    ],
  },
  {
    question: 'partners_faq.question_2.question',
    answer: '',
    listAnswers: [
      'partners_faq.question_2.point1',
      'partners_faq.question_2.point2',
      'partners_faq.question_2.point3',
      'partners_faq.question_2.point4',
      'partners_faq.question_2.point5',
    ],
  },
  {
    question: 'partners_faq.question_3.question',
    answer: '',
    listAnswers: [
      'partners_faq.question_3.point1',
      'partners_faq.question_3.point2',
      'partners_faq.question_3.point3',
      'partners_faq.question_3.point4',
    ],
  },
  {
    question: 'partners_faq.question_4.question',
    answer: '',
    listAnswers: [
      'partners_faq.question_4.point1',
      'partners_faq.question_4.point2',
    ],
  },
  {
    question: 'partners_faq.question_5.question',
    // answer: 'partners_faq.question_5.answer',
    answer: '',
    listAnswers: [
      'partners_faq.question_5.point1',
      'partners_faq.question_5.point2',
    ],
  },
];
