import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';

import { BankCard } from '@/components/BankCard';
import { useCmsContent } from '@/contexts/CmsContentContext';
import useFavouriteDeposits from '@/hooks/useFavouriteDeposits';
import { getCountryFlagURL } from '@/services/supabase';
import { useSearchStore } from '@/store';
import type { IBankDepositCard } from '@/types';
import { capitalizeFirstLetter, cn } from '@/utils';
import { findCountryKey } from '@/utils/countries';

interface DepositCardsProps {
  isLoading: boolean;
  onLoadMore: () => void;
  // isFavorited: (bank: IBankDepositCard) => boolean;
  deposits: IBankDepositCard[];
  loadMoreText?: string;
  shouldLoadMore?: boolean;
  shouldRenderHeading?: boolean;
  isBlogBankCard?: boolean;
  className?: string;
  loadMoreClassName?: string;
}

export const DepositCards: FC<DepositCardsProps> = ({
  deposits,
  isLoading,
  onLoadMore,
  shouldLoadMore = true,
  shouldRenderHeading = true,
  isBlogBankCard,
  loadMoreText,
  className,
  loadMoreClassName,
  // isFavorited,
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { country: countryStore } = useSearchStore((state) => state);
  const { country: initialCountry } = useCmsContent();

  const { handleFavourite, checkIsFavorite } = useFavouriteDeposits();

  return (
    <div
      className={cn(
        'flex w-full flex-col items-center justify-center',
        className,
      )}
    >
      {shouldRenderHeading && (
        <div className="mb-4 mt-10 flex w-full flex-col gap-1">
          <h5 className="text-blue-90 text-[18px]">
            {t('deposits.other_deposit_text')}
          </h5>
          <div className="flex gap-2">
            <p className="body2">{t('deposits.other_deposit_description')}</p>
            <div className="body2-bold flex content-center items-center gap-1">
              <Image
                src={getCountryFlagURL(
                  findCountryKey(router.query.country as string) ||
                    initialCountry.name,
                )}
                alt={countryStore}
                className="h-3 w-4"
                width={16}
                height={12}
              />
              <p>
                {t(
                  `database.countries.${
                    capitalizeFirstLetter(
                      findCountryKey(router.query.country as string),
                    ) || initialCountry.name
                  }`,
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4 flex w-full flex-col gap-4 space-y-3">
        {deposits.map((bank) => (
          <BankCard
            key={`all_deposits_${bank.productIndex}`}
            bank={bank}
            isFavourite={checkIsFavorite(bank)}
            handleFavourite={() => handleFavourite(bank)}
            isBlogBankCard={isBlogBankCard}
          />
        ))}
      </div>
      {shouldLoadMore && (
        <button
          type="button"
          onClick={onLoadMore}
          disabled={isLoading}
          className={cn(
            'btn-blue-states mt-4 rounded-full px-8 py-2 disabled:bg-grey-300',
            loadMoreClassName,
          )}
        >
          {isLoading
            ? t('global.loading')
            : loadMoreText || t('global.load_more')}
        </button>
      )}
    </div>
  );
};
