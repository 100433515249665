import { useTranslation } from 'next-i18next';
import React from 'react';

import {
  BestOffersModal,
  QuestionOpenDeposit,
  SMSConfirmation,
} from '@/components/modals';
import type { BestOffersModalProps } from '@/components/modals/BestOffersModal';
import type { PhoneInputModalProps } from '@/components/modals/PhoneInputModal';
import { PhoneInputModal } from '@/components/modals/PhoneInputModal';
import type { SMSConfirmationProps } from '@/components/modals/SMSConfirmation';
import { useModal } from '@/contexts/ModalContext';

export const useShowModal = () => {
  const modal = useModal();
  const { t } = useTranslation();

  return {
    showPhoneInputModal: ({ onSuccess, onSubmit }: PhoneInputModalProps) => {
      modal.openModal(
        <PhoneInputModal onSuccess={onSuccess} onSubmit={onSubmit} />,
        t('compliance.step_verification'),
      );
    },
    showSMSVerificationModal: ({
      onSuccess,
      verificationUuid,
      onSubmit,
    }: SMSConfirmationProps) => {
      modal.openModal(
        <SMSConfirmation
          onSuccess={onSuccess}
          verificationUuid={verificationUuid}
          onSubmit={onSubmit}
        />,
        t('compliance.step_verification'),
      );
    },
    showQuestionOpenDepositModal: ({
      productIndex,
    }: {
      productIndex?: string;
    }) => {
      modal.openModal(
        <QuestionOpenDeposit productIndex={productIndex} />,
        t('open_deposit_modal.title'),
      );
    },
    showBestOffersModal: ({ products }: BestOffersModalProps) => {
      modal.openModal(
        <BestOffersModal products={products} />,
        t('global.you-might-be-interested'),
      );
    },
  };
};
