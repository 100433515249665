import { SourceOfFundsUserAttributeEnum } from '@pickthebank/api-sdk/index';
import type { ProfileAttributesDto } from '@pickthebank/api-sdk/type/request/user/profile-attributes.dto';
import { AttributeTypesNames } from '@pickthebank/api-sdk/type/request/user/profile-attributes.dto';
import type { ICountryDTO } from '@pickthebank/api-sdk/type/response/public/reference-books/countryList.dto';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Button, SelectInput, TextInput } from '@/components';
import { DialogMyDataList } from '@/components/Dialog/parts/DialogMyDataList';
import { Flag } from '@/components/Flag';
import { useApi } from '@/contexts/ApiContext';
import useUserInformation from '@/hooks/useUserInformation';
import type { UserData, UserProfile } from '@/layouts/Application';
import {
  MARITAL_STATUS_OPTIONS,
  MONTHS,
  prepareUserAttributes,
  US_TAX_RESIDENCY_OPTIONS,
} from '@/layouts/Compliance/utils';
import { getCountriesList } from '@/services/supabase';
import { cn } from '@/utils';

interface UserAttributesProps {
  editable?: boolean;
  className?: string;
}
export const UserAttributes: FC<UserAttributesProps> = ({
  editable = false,
  className,
}) => {
  const { t } = useTranslation();
  const { getUserProfileAttributes } = useApi();

  const { getUserProfile, putUserProfileAttributes } = useApi();
  const { fetchUserInformation, isBaseAttributesCompleted } =
    useUserInformation();
  const [countries, setCountries] = useState<ICountryDTO[]>([]);

  const [userData, setUserData] = useState<UserData | undefined>();
  const [attributes, setAttributes] = useState<any>([]);
  const [updatedUserData, setUpdatedUserData] = useState<
    UserData | undefined
  >();
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();
  const [editGroup, setEditGroup] = useState<string | undefined>(undefined);
  const getMaritalOptionTranslation = (value: string) => {
    const option = MARITAL_STATUS_OPTIONS.find((item) => item.value === value);
    if (option) {
      return t(option.translationKey);
    }
    return value;
  };

  const basicInformationData = [
    {
      title: t('global.name'),
      value:
        userData?.firstName && userData?.lastName
          ? `${userData?.firstName} ${userData?.lastName}`
          : undefined,
    },
    {
      title: t('compliance.basic_information.birthday'),
      value: userData?.birthday
        ? `${userData?.birthday?.toLocaleDateString()}`
        : undefined,
    },
    {
      title: t('compliance.basic_information.nationality'),
      value: userData?.nationality?.value ? (
        <span className="flex items-center gap-1">
          <Flag
            countryCode={userData.nationality.prefix?.toLowerCase() as string}
          />
          {userData.nationality?.title}
        </span>
      ) : undefined,
    },
  ];

  const contactInformationData = [
    {
      title: t('compliance.address.phone_number'),
      value: userData?.phoneNumber,
    },
    {
      title: t('global.email'),
      value: userProfile?.email,
    },
  ];

  const addressInformationData = [
    {
      title: t('compliance.address.full_address'),
      value: `${userData?.fullAddress || '—'}\n${userData?.city || '—'}\n${
        userData?.index || '—'
      }\n${userData?.countryOfTaxResidence?.title || '—'}`,
    },
  ];

  const additionalInformationData = [
    {
      title: t('compliance.additional_information.profession'),
      value: userData?.profession,
    },
    {
      title: t('compliance.additional_information.marital_status'),
      value: userData?.maritalStatus
        ? getMaritalOptionTranslation(userData.maritalStatus.value as string)
        : undefined,
    },
    {
      title: t('compliance.additional_information.tin_number'),
      value: userData?.tinNumber,
    },
    {
      title: t('compliance.additional_information.country_of_birth'),
      value: userData?.countryOfBirth?.value ? (
        <span className="flex items-center gap-1">
          <Flag
            countryCode={
              userData.countryOfBirth.prefix?.toLowerCase() as string
            }
          />
          {userData.countryOfBirth?.title}
        </span>
      ) : undefined,
    },
    {
      title: t('compliance.basic_information.country_of_tax_residence'),
      value: userData?.countryOfTaxResidence?.value ? (
        <span className="flex items-center gap-1">
          <Flag
            countryCode={
              userData.countryOfTaxResidence.prefix?.toLowerCase() as string
            }
          />
          {userData.countryOfTaxResidence?.title}
        </span>
      ) : undefined,
    },
    {
      title: t('compliance.source-of-funds.title'),
      value: userData?.sourceOfFunds?.value?.length
        ? `${userData?.sourceOfFunds?.value
            .filter(
              (item: string) => item !== SourceOfFundsUserAttributeEnum.Other,
            )
            .map((item: string) => t(`compliance.source-of-funds.item.${item}`))
            .join(
              ', ',
            )}${userData?.sourceOfFundsOther ? `, ${userData?.sourceOfFundsOther}` : ''}`
        : undefined,
    },
    {
      title: t('compliance.source-of-wealth.title'),
      value: userData?.sourceOfWealth?.value?.length
        ? `${userData?.sourceOfWealth?.value
            .filter(
              (item: string) => item !== SourceOfFundsUserAttributeEnum.Other,
            )
            .map((item: string) => t(`compliance.source-of-funds.item.${item}`))
            .join(
              ', ',
            )}${userData?.sourceOfWealthOther ? `, ${userData?.sourceOfWealthOther}` : ''}`
        : undefined,
    },
    {
      title: t('compliance.approximate-wealth-short.title'),
      value: userData?.approximateWealthValue,
    },
    {
      title: t('compliance.approximate-income-short.title'),
      value: userData?.approximateAnnualIncomeValue,
    },
    {
      title: t('compliance.reference-account'),
      value: userData?.referencedAccount,
    },
    {
      title: t('compliance.pep.title_short'),
      value: userData?.isPep?.value[0]
        ? t(`compliance.pep.item.${userData?.isPep?.value[0]}`)
        : undefined,
    },
    {
      title: t('compliance.additional_information.us_tax_residency_status'),
      value: userData?.usTaxResidency?.title,
    },
  ];

  const fetchUserProfileAttributes = useCallback(async () => {
    try {
      const lAttributes = await getUserProfileAttributes();
      setAttributes(lAttributes);
      const data = prepareUserAttributes(lAttributes);

      const date = new Date(data[AttributeTypesNames.Birthday]);
      let localYear: number | undefined;
      let localMonth: number | undefined;
      let localDay: number | undefined;
      if (date) {
        localYear = date.getFullYear();
        localMonth = date.getMonth();
        localDay = date.getDate();
      }

      const preparedInitialData = {
        firstName: data[AttributeTypesNames.FirstName],
        lastName: data[AttributeTypesNames.LastName],
        nationality: data[AttributeTypesNames.CitizenshipCountry],
        countryOfTaxResidence: data[AttributeTypesNames.ResidenceCountry],
        birthday: data[AttributeTypesNames.Birthday]
          ? new Date(data[AttributeTypesNames.Birthday])
          : undefined,
        year: localYear ? { title: localYear!, value: localYear! } : undefined,
        month:
          localMonth !== undefined
            ? { title: MONTHS[localMonth!]!, value: MONTHS[localMonth!]! }
            : undefined,
        day:
          localDay !== undefined
            ? { title: localDay!, value: localDay! }
            : undefined,
        city: data[AttributeTypesNames.City],
        fullAddress: data[AttributeTypesNames.Address],
        index: data[AttributeTypesNames.ZipCode],
        phoneNumber: data[AttributeTypesNames.PhoneNumber],
        profession: data[AttributeTypesNames.Profession],
        maritalStatus: data[AttributeTypesNames.MaritalStatus]
          ? {
              title: getMaritalOptionTranslation(
                data[AttributeTypesNames.MaritalStatus],
              ),
              value: data[AttributeTypesNames.MaritalStatus],
            }
          : undefined,
        tinNumber: data[AttributeTypesNames.TinNumber],
        countryOfBirth: data[AttributeTypesNames.CountryOfBirth],
        usTaxResidency:
          data[AttributeTypesNames.IsUsTaxResident] === undefined
            ? undefined
            : {
                title: data[AttributeTypesNames.IsUsTaxResident]
                  ? t(US_TAX_RESIDENCY_OPTIONS[0]!.translationKey)
                  : t(US_TAX_RESIDENCY_OPTIONS[1]!.translationKey),
                value: data[AttributeTypesNames.IsUsTaxResident],
              },
        sourceOfFunds: data[AttributeTypesNames.SourceOfFunds],
        sourceOfFundsOther: data[AttributeTypesNames.SourceOfFundsExtra],
        sourceOfWealth: data[AttributeTypesNames.SourceOfWealth],
        sourceOfWealthOther: data[AttributeTypesNames.SourceOfWealthExtra],
        isPep: data[AttributeTypesNames.IsPEP],
        approximateWealthValue: data.approximate_wealth_value,
        approximateAnnualIncomeValue: data.approximate_annual_income_value,
        referencedAccount: data[AttributeTypesNames.ReferencedAccount],
      };

      setUserData(() => {
        return preparedInitialData;
      });
    } catch (e) {
      console.error('error', e);
    }
  }, []);
  const fetchUserProfile = useCallback(async () => {
    try {
      const data = await getUserProfile();
      setUserProfile(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchCountries = useCallback(async () => {
    const data = await getCountriesList();
    setCountries(data);
  }, []);

  useEffect(() => {
    fetchUserProfile();
    fetchUserProfileAttributes();
    fetchCountries();
  }, []);

  const preparedCountries = countries.map((c: ICountryDTO) => {
    return {
      title: c.name,
      value: c.alpha2,
      prefix: c.alpha2?.toLowerCase(),
    };
  });

  const checkIsEditable = (name: string) => {
    const attribute = attributes.find((item: any) => item.name === name);
    return !attribute?.editable;
  };

  return (
    <div className={cn(className, 'flex flex-col gap-8')}>
      {userData && (
        <>
          <DialogMyDataList
            title={t('compliance.basic_information.title')}
            items={basicInformationData}
            action={{
              title: t('global.contact_support_to_edit'),
              callback: () => {
                window.location.href = 'mailto:info@pickthebank.eu';
              },
            }}
            isEditable={editable && isBaseAttributesCompleted}
          />
          <DialogMyDataList
            title={t('application.contact_information')}
            items={contactInformationData}
            action={{
              title: t('global.contact_support_to_edit'),
              callback: () => {
                window.location.href = 'mailto:info@pickthebank.eu';
              },
            }}
            isEditable={editable && isBaseAttributesCompleted}
          >
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <TextInput
                  value={updatedUserData?.phoneNumber as string}
                  placeholder={t('compliance.address.full_address')}
                  onChange={(e) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      fullAddress: e,
                    }));
                  }}
                  className="mt-4"
                  disabled={checkIsEditable(AttributeTypesNames.PhoneNumbers)}
                />
                <TextInput
                  value={userProfile?.email as string}
                  placeholder={t('compliance.address.full_address')}
                  onChange={(e) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      fullAddress: e,
                    }));
                  }}
                  disabled
                />
              </div>
              <div className="flex justify-end gap-2">
                <Button
                  color="secondary"
                  className="min-w-[130px] py-[18px] md:min-w-[160px]"
                  props={{
                    onClick: () => {
                      setEditGroup(undefined);
                      setUpdatedUserData(undefined);
                    },
                  }}
                >
                  {t('global.cancel')}
                </Button>
                <Button
                  color="blue"
                  className="min-w-[130px] py-[18px] md:min-w-[160px]"
                  props={{
                    onClick: async () => {
                      const payload = {
                        [AttributeTypesNames.Address]:
                          updatedUserData?.fullAddress as string,
                        [AttributeTypesNames.City]: updatedUserData?.city,
                        [AttributeTypesNames.ZipCode]: updatedUserData?.index,
                        [AttributeTypesNames.ResidenceCountry]:
                          updatedUserData?.countryOfTaxResidence?.value,
                      };
                      await putUserProfileAttributes({
                        attributes: payload,
                      } as ProfileAttributesDto);
                      await fetchUserProfileAttributes();
                      await fetchUserInformation();
                      setEditGroup(undefined);
                      setUpdatedUserData(undefined);
                    },
                    disabled:
                      !updatedUserData?.fullAddress ||
                      !updatedUserData?.city ||
                      !updatedUserData?.index ||
                      !updatedUserData?.countryOfTaxResidence?.value,
                  }}
                >
                  {t('global.save-changes')}
                </Button>
              </div>
            </div>
          </DialogMyDataList>
          <DialogMyDataList
            title={t('contact_info.adress')}
            items={addressInformationData}
            action={{
              title: t('global.edit'),
              callback: () => {
                setUpdatedUserData(JSON.parse(JSON.stringify(userData)));
                setEditGroup('address');
              },
            }}
            isEditing={editGroup === 'address'}
            isEditable={editable && isBaseAttributesCompleted}
          >
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <SelectInput
                  placeholder={t('compliance.address.country')}
                  value={updatedUserData?.countryOfTaxResidence}
                  options={preparedCountries}
                  isScrolling={false}
                  onChange={(v) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      countryOfTaxResidence: v,
                    }));
                  }}
                  className="mt-4"
                  type="country"
                  disabled={checkIsEditable(
                    AttributeTypesNames.ResidenceCountry,
                  )}
                />
                <TextInput
                  value={updatedUserData?.fullAddress as string}
                  placeholder={t('compliance.address.full_address')}
                  onChange={(e) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      fullAddress: e,
                    }));
                  }}
                  disabled={checkIsEditable(AttributeTypesNames.Address)}
                />
                <div className="flex gap-2">
                  <TextInput
                    value={updatedUserData?.city as string}
                    placeholder={t('compliance.address.city')}
                    onChange={(e) => {
                      setUpdatedUserData((prev) => ({ ...prev, city: e }));
                    }}
                    disabled={checkIsEditable(AttributeTypesNames.City)}
                  />
                  <TextInput
                    value={updatedUserData?.index as string}
                    placeholder={t('compliance.address.zip_code')}
                    onChange={(e) => {
                      setUpdatedUserData((prev) => ({ ...prev, index: e }));
                    }}
                    disabled={checkIsEditable(AttributeTypesNames.ZipCode)}
                  />
                </div>
              </div>
              <div className="flex justify-end gap-2">
                <Button
                  color="secondary"
                  className="min-w-[130px] py-[18px] md:min-w-[160px]"
                  props={{
                    onClick: () => {
                      setEditGroup(undefined);
                      setUpdatedUserData(undefined);
                    },
                  }}
                >
                  {t('global.cancel')}
                </Button>
                <Button
                  color="blue"
                  className="min-w-[130px] py-[18px] md:min-w-[160px]"
                  props={{
                    onClick: async () => {
                      const payload = {
                        [AttributeTypesNames.Address]:
                          updatedUserData?.fullAddress,
                        [AttributeTypesNames.City]: updatedUserData?.city,
                        [AttributeTypesNames.ZipCode]: updatedUserData?.index,
                        [AttributeTypesNames.ResidenceCountry]:
                          updatedUserData?.countryOfTaxResidence?.value,
                      };
                      await putUserProfileAttributes({
                        attributes: payload,
                      } as ProfileAttributesDto);
                      await fetchUserProfileAttributes();
                      await fetchUserInformation();
                      setEditGroup(undefined);
                      setUpdatedUserData(undefined);
                    },
                    disabled:
                      !updatedUserData?.fullAddress ||
                      !updatedUserData?.city ||
                      !updatedUserData?.index ||
                      !updatedUserData?.countryOfTaxResidence?.value,
                  }}
                >
                  {t('global.save-changes')}
                </Button>
              </div>
            </div>
          </DialogMyDataList>
          <DialogMyDataList
            title={t('compliance.additional_information.title')}
            items={additionalInformationData as any}
            action={{
              title: t('global.edit'),
              callback: () => {
                setUpdatedUserData(JSON.parse(JSON.stringify(userData)));
                setEditGroup('additional');
              },
            }}
            isEditing={editGroup === 'additional'}
            isEditable={editable && isBaseAttributesCompleted}
          >
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <TextInput
                  placeholder={t(
                    'compliance.additional_information.profession',
                  )}
                  value={updatedUserData?.profession || ''}
                  onChange={(e) => {
                    setUpdatedUserData((prev) => ({ ...prev, profession: e }));
                  }}
                  className="mt-4"
                  disabled={checkIsEditable(AttributeTypesNames.Profession)}
                />
                <SelectInput
                  placeholder={t(
                    'compliance.additional_information.marital_status',
                  )}
                  value={updatedUserData?.maritalStatus}
                  options={MARITAL_STATUS_OPTIONS.map((status) => {
                    return {
                      title: t(status.translationKey),
                      value: status.value,
                    };
                  })}
                  onChange={(v) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      maritalStatus: v,
                    }));
                  }}
                  isScrolling={false}
                  disabled={checkIsEditable(AttributeTypesNames.MaritalStatus)}
                />
                <TextInput
                  placeholder={t(
                    'compliance.additional_information.tin_number',
                  )}
                  value={updatedUserData?.tinNumber || ''}
                  onChange={(e) => {
                    setUpdatedUserData((prev) => ({ ...prev, tinNumber: e }));
                  }}
                  disabled={checkIsEditable(AttributeTypesNames.TinNumber)}
                />
                <TextInput
                  placeholder={t('Approximate wealth value')}
                  value={updatedUserData?.approximateWealthValue || ''}
                  onChange={(e) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      approximateWealthValue: e,
                    }));
                  }}
                  disabled={checkIsEditable(
                    AttributeTypesNames.ApproximateWealthValue,
                  )}
                />
                <TextInput
                  placeholder={t('Approximate annual income')}
                  value={updatedUserData?.approximateAnnualIncomeValue || ''}
                  onChange={(e) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      approximateAnnualIncomeValue: e,
                    }));
                  }}
                  disabled={checkIsEditable(
                    AttributeTypesNames.ApproximateAnnualIncomeValue,
                  )}
                />
                <TextInput
                  placeholder={t('compliance.reference-account')}
                  value={updatedUserData?.referencedAccount || ''}
                  onChange={(e) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      referencedAccount: e,
                    }));
                  }}
                  disabled={checkIsEditable(
                    AttributeTypesNames.ReferencedAccount,
                  )}
                />
                <SelectInput
                  placeholder={t(
                    'compliance.additional_information.country_of_birth',
                  )}
                  value={updatedUserData?.countryOfBirth}
                  options={preparedCountries}
                  isScrolling={false}
                  onChange={(v) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      countryOfBirth: v,
                    }));
                  }}
                  type="country"
                  disabled={checkIsEditable(AttributeTypesNames.CountryOfBirth)}
                />
                <SelectInput
                  placeholder={t(
                    'compliance.basic_information.country_of_tax_residence',
                  )}
                  value={updatedUserData?.countryOfTaxResidence}
                  options={preparedCountries}
                  isScrolling={false}
                  onChange={(v) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      countryOfTaxResidence: v,
                    }));
                  }}
                  type="country"
                  disabled={checkIsEditable(
                    AttributeTypesNames.ResidenceCountry,
                  )}
                />
                <SelectInput
                  placeholder={t(
                    'compliance.additional_information.us_tax_residency_status',
                  )}
                  value={updatedUserData?.usTaxResidency}
                  options={US_TAX_RESIDENCY_OPTIONS.map((option) => {
                    return {
                      title: t(option.translationKey),
                      value: option.value,
                    };
                  })}
                  onChange={(v) => {
                    setUpdatedUserData((prev) => ({
                      ...prev,
                      usTaxResidency: v,
                    }));
                  }}
                  isScrolling={false}
                  disabled={checkIsEditable(
                    AttributeTypesNames.IsUsTaxResident,
                  )}
                />
              </div>
              <div className="flex justify-end gap-2">
                <Button
                  color="secondary"
                  className="min-w-[130px] py-[18px] md:min-w-[160px]"
                  props={{
                    onClick: () => {
                      setEditGroup(undefined);
                      setUpdatedUserData(undefined);
                    },
                  }}
                >
                  {t('global.cancel')}
                </Button>
                <Button
                  color="blue"
                  className="min-w-[130px] py-[18px] md:min-w-[160px]"
                  props={{
                    onClick: async () => {
                      const payload = {
                        [AttributeTypesNames.Profession]:
                          updatedUserData?.profession,
                        [AttributeTypesNames.MaritalStatus]:
                          updatedUserData?.maritalStatus?.value,
                        [AttributeTypesNames.TinNumber]:
                          updatedUserData?.tinNumber,
                        [AttributeTypesNames.CountryOfBirth]:
                          updatedUserData?.countryOfBirth?.value,
                        [AttributeTypesNames.ResidenceCountry]:
                          updatedUserData?.countryOfTaxResidence?.value,
                        [AttributeTypesNames.IsUsTaxResident]:
                          updatedUserData?.usTaxResidency?.value,
                        [AttributeTypesNames.ReferencedAccount]:
                          updatedUserData?.referencedAccount,
                      };
                      await putUserProfileAttributes({
                        attributes: payload,
                      } as ProfileAttributesDto);
                      await fetchUserProfileAttributes();
                      await fetchUserInformation();
                      setEditGroup(undefined);
                      setUpdatedUserData(undefined);
                    },
                    disabled:
                      !updatedUserData?.profession ||
                      !updatedUserData?.maritalStatus ||
                      !updatedUserData?.tinNumber ||
                      !updatedUserData?.countryOfBirth ||
                      !updatedUserData?.countryOfTaxResidence ||
                      !updatedUserData?.usTaxResidency,
                  }}
                >
                  {t('global.save-changes')}
                </Button>
              </div>
            </div>
          </DialogMyDataList>
        </>
      )}
    </div>
  );
};
