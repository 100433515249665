import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes';

import { useCmsContent } from '@/contexts/CmsContentContext';
import { useSearchStore } from '@/store/storeSearch';
import {
  prepareSearchQueryToStore,
  prepareSearchStoreToQuery,
  prepareSearchUrlToStore,
} from '@/store/storeSearch.utils';
import { annualFeeKeys } from '@/utils/annualFees';
import { cashbackKeys } from '@/utils/cashback';
import { COUNTRIES_PATHS } from '@/utils/countries';
import { termKeys } from '@/utils/termKeys';

type QueryParamKey = 'country' | 'currency' | 'terms' | 'types' | 'is_partner';

type ExtendedQueryParamKey = QueryParamKey | 'annualFee' | 'cashback';

export const useDepositsLayout = () => {
  const router = useRouter();
  const patchSearchValue = useSearchStore((state) => state.patchSearchValue);
  const resetStore = useSearchStore((state) => state.resetSearchState);
  const { country: initialCountry } = useCmsContent();

  const { i18n } = useTranslation();

  const updateQueryWithStore = (
    pathname = router.pathname,
    excludedParams: QueryParamKey[] = [],
    scroll: boolean = false,
  ) => {
    const { country, currency, terms, is_partner, types } =
      useSearchStore.getState();

    const payload = {
      country,
      currency,
      terms,
      types,
      is_partner,
    };

    excludedParams.forEach((key) => {
      delete payload[key];
    });

    const query = prepareSearchStoreToQuery(payload);

    router.push(
      {
        pathname,
        query,
      },
      undefined,
      { scroll, shallow: false },
    );
  };

  const updateSavingsAccountsQueryWithStore = (
    pathname = router.pathname,
    scroll: boolean = true,
  ) => {
    const { country, currency, annualFee, cashback } =
      useSearchStore.getState();

    const payload = {
      country,
      currency,
      annualFee,
      cashback,
    };

    let route = `${pathname}/`;

    if (payload.country) {
      route += `${COUNTRIES_PATHS[payload.country][i18n.language]}/`;
    }

    if (payload.currency) {
      route += `${payload.currency}/`;
    }

    if (payload.annualFee) {
      route += `${annualFeeKeys[payload.annualFee.key][i18n.language]}/`;
    }

    if (payload.cashback) {
      route += `${cashbackKeys[payload.cashback.key][i18n.language]}/`;
    }
    router.push({ pathname: route }, undefined, {
      shallow: false,
      scroll,
    });
  };

  const updateUrlWithQuery = (
    pathname = router.pathname,
    excludedParams: QueryParamKey[] = [],
  ) => {
    const { country, currency, terms, is_partner, types } =
      useSearchStore.getState();

    const payload = {
      country,
      currency,
      terms,
      types,
      is_partner,
    };

    excludedParams.forEach((key) => {
      delete payload[key];
    });

    let route = `${pathname}/`;
    if (payload.country) {
      route += `${COUNTRIES_PATHS[payload.country][i18n.language]}/`;
    }
    if (payload.terms) {
      const selectedTerms = terms.filter((term) => term.selected);
      const selectedKeys = selectedTerms.map((term) => {
        return termKeys[term.value][i18n.language];
      });
      route += `${
        selectedKeys.length === 0
          ? termKeys.all[i18n.language]
          : selectedKeys.join('+')
      }/`;
    }
    if (payload.currency) {
      route += `${payload.currency}/`;
    }

    router.push({ pathname: route }, undefined, {
      shallow: false,
      scroll: false,
    });
  };

  const getUrlWithSearchParams = (
    pathname = router.pathname,
    excludedParams: ExtendedQueryParamKey[] = [],
    country = initialCountry.name.toLowerCase(),
  ) => {
    const { currency, terms, is_partner, types, cashback, annualFee } =
      useSearchStore.getState();

    const payload = {
      country,
      currency,
      terms,
      types,
      is_partner,
      cashback,
      annualFee,
    };

    excludedParams.forEach((key) => {
      delete payload[key];
    });

    let route = `${pathname}/`;
    if (payload.country) {
      route += `${COUNTRIES_PATHS[payload.country][i18n.language]}/`;
    }
    if (payload.terms) {
      const selectedTerms = terms.filter((term) => term.selected);
      const selectedKeys = selectedTerms.map((term) => {
        return termKeys[term.value][i18n.language];
      });
      route += `${
        selectedKeys.length === 0
          ? termKeys.all[i18n.language]
          : selectedKeys.join('+')
      }/`;
    }
    if (payload.currency) {
      route += `${payload.currency}/`;
    }
    if (payload.annualFee) {
      route += `${annualFeeKeys[payload.annualFee.key][i18n.language]}/`;
    }

    if (payload.cashback) {
      route += `${cashbackKeys[payload.cashback.key][i18n.language]}/`;
    }
    return { pathname: route };
  };

  const generateLinkWithQuery = (
    pathname = router.pathname,
    excludedParams: QueryParamKey[] = [],
  ) => {
    const { country, currency, terms, is_partner, types } =
      useSearchStore.getState();

    const payload = {
      country,
      currency,
      terms,
      types,
      is_partner,
    };

    excludedParams.forEach((key) => {
      delete payload[key];
    });

    const query = prepareSearchStoreToQuery(payload);

    return {
      pathname,
      query,
    };
  };

  const syncQueryWithStore = () => {
    const payload = prepareSearchQueryToStore(router.query);
    resetStore();
    patchSearchValue(payload);
  };

  const syncUrlWithStore = () => {
    const payload = prepareSearchUrlToStore({
      ...router.query,
      country: router.query.country
        ? router.query.country
        : initialCountry.name.toLowerCase(),
      currency: router.query.currency
        ? router.query.currency
        : initialCountry.currency,
    });
    resetStore();
    patchSearchValue(payload);
  };

  return {
    updateQueryWithStore,
    syncQueryWithStore,
    syncUrlWithStore,
    generateLinkWithQuery,
    updateSavingsAccountsQueryWithStore,
    updateUrlWithQuery,
    getUrlWithSearchParams,
  };
};
