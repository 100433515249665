import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import React from 'react';

import { useCmsContent } from '@/contexts/CmsContentContext';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices } from '@/utils';

import { ChangeLanguage } from '../ChangeLanguage';
import { Container } from '../Container';
import { Icon } from '../Sprite';
import type { IFooterLink } from './footer.types';
import { getLinksFromData } from './footer.utils';

const TechinalInforamtion = () => {
  return (
    <div className="hidden w-[272px] shrink-0 flex-col gap-12 md:flex">
      <Link href="/" aria-label="Home">
        <Icon
          className="h-[36px] w-[204px] pr-6 text-[20px] font-bold text-transparent"
          name="LogoHeader"
        />
      </Link>

      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4 ">
          <Icon
            className="hidden size-5 text-transparent md:flex"
            name="phone"
          />
          <p className="body2 tracking-[0.2px] text-grey-800">
            +357 99 27 27 90
          </p>
        </div>
        <div className="flex items-center gap-4 text-white">
          <Icon
            className="hidden size-5 text-transparent md:flex"
            name="email"
          />
          <p className="body2 tracking-[0.1px] text-grey-800">
            info@pickthebank.eu
          </p>
        </div>
        <div className="flex items-start gap-4 ">
          <p className="hidden size-5 md:flex" />
          <p className="body2 tracking-[0.2px] text-grey-400">
            225, Arch. Makariou III Avenue
            <br />
            Oriana Court, 3rd Floor, Office 4<br />
            3105 Limassol, Cyprus
          </p>
        </div>
      </div>
    </div>
  );
};

const AboutUs = ({
  footerLinks,
  getAnalyticEvent,
}: {
  footerLinks: IFooterLink[] | [];
  getAnalyticEvent: (linkTitle: string) => () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-6 w-[170px] md:mt-0">
      <h3 className="body2-bold  tracking-[0.2px] text-blue-900">
        {t('footer.About us')}
      </h3>
      <ul role="menu" className="mt-[13px] space-y-3">
        <li>
          <Link
            onClick={() => {
              analyticServices.sendEvent({
                action: AnalyticsEvent.footerClickAbout,
                category: AnalyticsCategory.main,
                label: 'footerClickAbout',
              });
            }}
            href="/about-us"
            className="body2 tracking-[0.2px] text-grey-800 hover:text-blue-900"
          >
            {t('footer.About us')}
          </Link>
        </li>
        {footerLinks.map((item) => (
          <li key={item.id}>
            <Link
              onClick={getAnalyticEvent(item.title)}
              href={`${item.link}`}
              className="body2 tracking-[0.2px] text-grey-800 hover:text-blue-900"
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const GetStarted = ({
  footerLinks,
  getAnalyticEvent,
}: {
  footerLinks: IFooterLink[] | [];
  getAnalyticEvent: (linkTitle: string) => () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-6 w-[170px] md:mt-0">
      <h3 className="body2-bold tracking-[0.2px] text-blue-900">
        {t('footer.Get started')}
      </h3>
      <ul role="menu" className="mt-[13px] space-y-3">
        <li>
          <Link
            onClick={() => {
              analyticServices.sendEvent({
                action: AnalyticsEvent.footerClickHome,
                category: AnalyticsCategory.main,
                label: 'footerClickHome',
              });
            }}
            href="/"
            className="body2 tracking-[0.2px] text-grey-800 hover:text-blue-900"
          >
            {t('footer.Home')}
          </Link>
        </li>
        <li>
          <Link
            href="/statistics/savings-accounts/"
            className="body2 tracking-[0.2px] text-grey-800 hover:text-blue-900"
          >
            {t('footer.savings-accounts')}
          </Link>
        </li>
        {footerLinks.map((item) => (
          <li key={item.id}>
            <Link
              onClick={getAnalyticEvent(item.title)}
              href={`/${item.link}`}
              className="body2 tracking-[0.2px] text-grey-800 hover:text-blue-900"
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Legal = ({
  footerLinks,
  getAnalyticEvent,
}: {
  footerLinks: IFooterLink[] | [];
  getAnalyticEvent: (linkTitle: string) => () => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div className="mt-6 w-[170px] md:mt-0">
      <h3 className="body2-bold tracking-[0.2px] text-blue-900">
        {t('footer.Legal')}
      </h3>
      <ul role="menu" className="mt-[13px] space-y-3">
        {footerLinks.map((item) => (
          <li key={item.id}>
            <span
              onClick={() => {
                getAnalyticEvent(item.title);
                router.push(item.link);
              }}
              className="body2 cursor-pointer tracking-[0.2px] text-grey-800 hover:text-blue-900"
            >
              {item.title}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
const RightsBottomInformation = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full border-t border-grey-200">
      <Container className="body3 my-2 px-1 text-left md:mb-[21px] md:mt-0 md:px-[36px] md:py-2 lg:mb-[21px] xl:mb-0 xl:pb-1 xl:pl-[42px] xl:pt-2">
        <p className="text-sm leading-[18px] tracking-[-0.4px] text-grey-400">
          &copy; 2025, PICK SAVINGS NAVIGATOR LTD, {t('footer.rights')}
        </p>
      </Container>
    </div>
  );
};

interface FooterProps {
  isFooterMinified?: boolean;
}

export const Footer: FC<FooterProps> = ({ isFooterMinified }) => {
  const { footerLinks } = useCmsContent();
  const { t } = useTranslation();

  const getAnalyticEvent = (linkTitle: string) => {
    switch (linkTitle) {
      case t('footer.ImprintImpressum'):
        return () => {
          analyticServices.sendEvent({
            action: AnalyticsEvent.footerClickImprint,
            category: AnalyticsCategory.main,
            label: 'footerClickImprint',
          });
        };
      case t('footer.Terms of Use'):
        return () => {
          analyticServices.sendEvent({
            action: AnalyticsEvent.footerClickTermsUse,
            category: AnalyticsCategory.main,
            label: 'footerClickTermsUse',
          });
        };
      case t('footer.Privacy Policy'):
        return () => {
          analyticServices.sendEvent({
            action: AnalyticsEvent.footerClickPrivacy,
            category: AnalyticsCategory.main,
            label: 'footerClickPrivacy',
          });
        };
      case t('footer.Personal Data Processing'):
        return () => {
          analyticServices.sendEvent({
            action: AnalyticsEvent.footerClickTermsService, // TODO: in future we need to change footerClickTermsService to footerClickDataProcessing
            category: AnalyticsCategory.main,
            label: 'footerClickTermsService',
          });
        };
      default:
        return () => {};
    }
  };

  return isFooterMinified ? (
    <footer>
      <Container className="flex justify-between pb-[7px] pt-[23px] lg:items-center">
        <div className="flex flex-col-reverse gap-4 lg:flex-row lg:items-center lg:gap-8">
          <p className="body3 text-grey-400">
            © 2023, Pick Savings Navigator LTD, All Rights Reserved
          </p>
          <div className="block md:hidden">
            <ChangeLanguage isFooter isMinified />
          </div>
          <div className="flex flex-wrap items-center gap-4 lg:gap-8">
            <Link
              href={t('terms-of-use.link')}
              className="body3 tracking-[0.2px] text-grey-800 hover:text-blue-900"
            >
              {t('footer.Terms of Use')}
            </Link>
            <Link
              href={t('privacy_policy.link')}
              className="body3 tracking-[0.2px] text-grey-800 hover:text-blue-900"
            >
              {t('footer.Privacy Policy')}
            </Link>
          </div>
        </div>
        <div className="hidden md:block">
          <ChangeLanguage isFooter isMinified />
        </div>
      </Container>
    </footer>
  ) : (
    <footer
      className="p-0 px-4 md:m-0 md:px-0 md:pt-0"
      aria-labelledby="footer-heading"
    >
      <Container className="mb-[52px] flex flex-col p-1 pr-4 md:grid md:grid-cols-[272px_238px] md:px-[36px] md:pt-[160px] lg:mb-0 lg:grid-cols-[272px_616px] lg:pb-12 lg:pt-[56px] xl:grid-cols-[272px_771px_122px] xl:py-16 xl:pl-[42px]">
        <Link href="/" aria-label="Home" className="mt-[59px] md:hidden">
          <Icon
            className="h-[36px] w-[204px] pr-6 text-[20px] font-bold text-transparent"
            name="LogoHeader"
          />
        </Link>
        <TechinalInforamtion />
        <div className="flex flex-col pt-2 md:mb-[62px] md:ml-[69px] md:gap-[23px] lg:ml-[58px] lg:flex-row lg:gap-6 xl:ml-[213px]">
          <AboutUs
            getAnalyticEvent={getAnalyticEvent}
            footerLinks={getLinksFromData(footerLinks, 'start')}
          />
          <GetStarted
            getAnalyticEvent={getAnalyticEvent}
            footerLinks={getLinksFromData(footerLinks, 'middle')}
          />
          <Legal
            getAnalyticEvent={getAnalyticEvent}
            footerLinks={getLinksFromData(footerLinks, 'end')}
          />
        </div>
        <div className="mb-6 mt-8 flex flex-col gap-4 md:hidden">
          <div className="flex items-center gap-4 ">
            <Icon
              className="hidden size-5 text-transparent md:flex"
              name="phone"
            />
            <p className="body2 tracking-[0.2px] text-grey-800">
              +357 99 27 27 90
            </p>
          </div>
          <div className="flex items-center gap-4 text-white">
            <Icon
              className="hidden size-5 text-transparent md:flex"
              name="email"
            />
            <p className="body2 tracking-[0.1px] text-grey-800">
              info@pickthebank.eu
            </p>
          </div>
          <div className="flex items-start gap-4 ">
            <p className="hidden size-5 md:flex" />
            <p className="body2 tracking-[0.2px] text-grey-400">
              225, Arch. Makariou III Avenue
              <br />
              Oriana Court, 3rd Floor, Office 4<br />
              3105 Limassol, Cyprus
            </p>
          </div>
        </div>
        <div className="items-start md:ml-[38px] lg:ml-9 lg:mt-0 lg:pb-4 lg:pt-6 xl:ml-6 xl:mt-0 xl:pt-2">
          <ChangeLanguage isFooter />
        </div>
      </Container>
      <RightsBottomInformation />
    </footer>
  );
};
